import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HierarchyComponent.css';
import Placeholder from '../utils/Placeholder';

const LoggedInAgentForm = ({ onAgentChange }) => {
  const [data, setData] = useState(null);
  const [originalDailyActivity, setOriginalDailyActivity] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const [editingCell, setEditingCell] = useState(null);
  const [formData, setFormData] = useState({});
  const previousCell = useRef(null);
  const inputRefs = useRef({});
  const editingRowRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const tableContainerRef = useRef(null);
  const [lagnnames, setLagnnames] = useState([]);
  const [selectedLagnname, setSelectedLagnname] = useState('');
  
  useEffect(() => {
    const fetchHierarchyInfo = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const loggedInAgent = localStorage.getItem('agnName'); // Retrieve logged-in agent name from local storage
        const response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getHierarchyInfo', {
          userId,
        });
        if (response.data.success) {
          const allowedAgents = response.data.data.map(item => item.lagnname);
          setLagnnames(allowedAgents);
          if (allowedAgents.includes(loggedInAgent)) {
            console.log('Logged-in agent:', loggedInAgent); // Log the logged-in agent name
            setSelectedLagnname(loggedInAgent);
            onAgentChange(loggedInAgent); // Notify parent about the selected agent
          } else {
            const defaultAgent = allowedAgents[0];
            console.log('Default selected agent:', defaultAgent); // Log the default selected agent
            setSelectedLagnname(defaultAgent);
            onAgentChange(defaultAgent); // Notify parent about the selected agent
          }
        } else {
          console.error('Failed to fetch hierarchy info');
        }
      } catch (error) {
        console.error('Error fetching hierarchy info:', error);
      }
    };
  
    fetchHierarchyInfo();
  }, [onAgentChange]);
  
  
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getHierarchyInfo', { userId });
        const responseDailyActivity = await axios.get('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getAllDailyActivity');
        
        if (response.data.success && responseDailyActivity.data.success) {
          const activityData = responseDailyActivity.data.data.map(activity => ({
            ...activity,
            reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
          }));
          setOriginalDailyActivity(activityData);
          const uniqueWeeks = getUniqueWeeks(activityData);
          setWeeks(uniqueWeeks);
          const newestWeekIndex = 0;
          setSelectedWeekIndex(newestWeekIndex);
  
          const agentData = response.data.data.find(agent => agent.lagnname === selectedLagnname);
          if (agentData) {
            if (agentData.clname === 'MGA') {
              agentData.MGA = agentData.lagnname;
            }
            setData(agentData);
          } else {
            setError('No data found for the selected agent');
          }
        } else {
          setError('No data found');
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
  
    if (selectedLagnname) {
      fetchData();
    }
  }, [selectedLagnname]);
  

useEffect(() => {
    if (weeks.length > 0) {
        filterDailyActivity(selectedWeekIndex, originalDailyActivity);
    }
}, [selectedWeekIndex, weeks, originalDailyActivity, selectedLagnname]);

  useEffect(() => {
    if (editingCell) {
      const { lagnname, dayIndex, columnIndex } = editingCell;
      const inputKey = `${lagnname}-${dayIndex}-${columnIndex}`;
      if (inputRefs.current[inputKey]) {
        inputRefs.current[inputKey].focus();
        inputRefs.current[inputKey].select();
      }
    }
  }, [editingCell]);

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (editingCell && editingRowRef.current && !editingRowRef.current.contains(event.target)) {
            if (event.target && event.target.className.includes('cancel-button')) {
                return; // Do nothing if the cancel button is clicked
            }
            if (!isSaving) {
                setIsSaving(true);
                setFormData((prevFormData) => {
                    handleSave(prevFormData, 'handleClickOutside');
                    return prevFormData;
                });
            }
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [editingCell, isSaving]);


  useEffect(() => {
    const fetchHierarchyInfo = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getHierarchyInfo', {
          userId,
        });
        if (response.data.success) {
          const allowedAgents = response.data.data.map(item => item.lagnname);
        } else {
          console.error('Failed to fetch hierarchy info');
        }
      } catch (error) {
        console.error('Error fetching hierarchy info:', error);
      }
    };

    fetchHierarchyInfo();
  }, []);

  useEffect(() => {
    if (weeks.length > 0) {
      filterDailyActivity(selectedWeekIndex, originalDailyActivity);
      const [mondayStr, sundayStr] = weeks[selectedWeekIndex].split(' - ');
      const monday = new Date(mondayStr);
      const sunday = new Date(sundayStr);
      const today = new Date();
      if (today >= monday && today <= sunday) {
        scrollToCurrentDay();
      }
    }
  }, [selectedWeekIndex, weeks, originalDailyActivity]);

  const getUniqueWeeks = (data) => {
    const weeks = {};
    data.forEach(item => {
      const date = new Date(item.reportDate);
      const monday = new Date(date.setDate(date.getDate() - ((date.getDay() + 6) % 7)));
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);
      const weekKey = `${(monday.getMonth() + 1).toString().padStart(2, '0')}/${monday.getDate().toString().padStart(2, '0')}/${monday.getFullYear()} - ${(sunday.getMonth() + 1).toString().padStart(2, '0')}/${sunday.getDate().toString().padStart(2, '0')}/${sunday.getFullYear()}`;
      if (!weeks[weekKey]) {
        weeks[weekKey] = true;
      }
    });

    const today = new Date();
    const currentMonday = new Date(today.setDate(today.getDate() - ((today.getDay() + 6) % 7)));
    const currentSunday = new Date(currentMonday);
    currentSunday.setDate(currentMonday.getDate() + 6);
    const currentWeekKey = `${(currentMonday.getMonth() + 1).toString().padStart(2, '0')}/${currentMonday.getDate().toString().padStart(2, '0')}/${currentMonday.getFullYear()} - ${(currentSunday.getMonth() + 1).toString().padStart(2, '0')}/${currentSunday.getDate().toString().padStart(2, '0')}/${currentSunday.getFullYear()}`;

    if (!weeks[currentWeekKey]) {
      weeks[currentWeekKey] = true;
    }

    return Object.keys(weeks).sort((a, b) => new Date(b.split(' - ')[0]) - new Date(a.split(' - ')[0]));
  };

  const handleWeekChange = (event) => {
    const newSelectedWeekIndex = weeks.indexOf(event.target.value);
    setSelectedWeekIndex(newSelectedWeekIndex);
    filterDailyActivity(newSelectedWeekIndex);
  };

  const handlePreviousWeek = () => {
    if (selectedWeekIndex < weeks.length - 1) {
      const newIndex = selectedWeekIndex + 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const handleNextWeek = () => {
    if (selectedWeekIndex > 0) {
      const newIndex = selectedWeekIndex - 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const getDatesOfWeek = (mondayDate) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(mondayDate);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };
  
  const handleLagnnameChange = (event) => {
    const newLagnname = event.target.value;
    setSelectedLagnname(newLagnname);
    onAgentChange(newLagnname); // Notify parent about the selected agent
  };
  

  const filterDailyActivity = (weekIndex, data = originalDailyActivity) => {
    const [mondayStr, sundayStr] = weeks[weekIndex].split(' - ');
    const monday = new Date(mondayStr);
    const sunday = new Date(sundayStr);
  
    const uniqueActivityMap = new Map();
  
    const filteredActivity = data.filter(activity => {
      const reportDate = new Date(activity.reportDate);
      const key = `${activity.agent}-${reportDate.toISOString().split('T')[0]}`;
  
      if (reportDate >= monday && reportDate <= sunday && activity.agent === selectedLagnname) {
        if (!uniqueActivityMap.has(key)) {
          uniqueActivityMap.set(key, true);
          return true;
        } else {
          console.log(`Duplicate activity found and excluded: ${activity.agent} on ${reportDate.toISOString().split('T')[0]}`);
        }
      }
      return false;
    });
  
    console.log('Filtered activity:', filteredActivity); // Log filtered activities to verify the result
  
    setDailyActivity(filteredActivity);
  };
  

  
const handleCellClick = async (agentData, dayIndex, columnIndex) => {


  if (editingCell && !isSaving) {
      console.log('handleCellClick: Saving previous cell before editing new cell');
      await handleSave(formData, 'handleCellClick');
  }

  const activity = getDailyActivityForAgent(agentData.lagnname, dayIndex + 1);

  setEditingCell({
      lagnname: agentData.lagnname,
      dayIndex,
      columnIndex,
      esid: agentData.esid,
      sa: agentData.sa,
      ga: agentData.ga,
      mga: agentData.mga
  });

  const newFormData = {
      appts: activity.appts && activity.appts !== 'N/A' ? activity.appts : '0',
      sits: activity.sits && activity.sits !== 'N/A' ? activity.sits : '0',
      sales: activity.sales && activity.sales !== 'N/A' ? activity.sales : '0',
      alp: activity.alp && activity.alp !== 'N/A' ? activity.alp : '0',
      refs: activity.refs && activity.refs !== 'N/A' ? activity.refs : '0',
      refSits: activity.refSits && activity.refSits !== 'N/A' ? activity.refSits : '0',
      refSales: activity.refSales && activity.refSales !== 'N/A' ? activity.refSales : '0',
      refAlp: activity.refAlp && activity.refAlp !== 'N/A' ? activity.refAlp : '0',
      refAppts: activity.refAppts && activity.refAppts !== 'N/A' ? activity.refAppts : '0',
  };

  console.log('New Form Data for Editing Cell:', newFormData);

  setFormData(newFormData);
  previousCell.current = { lagnname: agentData.lagnname, dayIndex, columnIndex };
  editingRowRef.current = document.querySelector(`#row-${agentData.lagnname}`);
};


const handleInputChange = (event) => {
  const { name, value } = event.target;
  const numericValue = (name === 'alp' || name === 'refAlp') ? parseFloat(value) : parseInt(value, 10);

  console.log(`Input changed - Name: ${name}, Value: ${value}, Numeric Value: ${numericValue}`);

  setFormData((prevFormData) => {
      const updatedFormData = {
          ...prevFormData,
          [name]: isNaN(numericValue) ? '' : numericValue,
      };
      console.log('Updated Form Data:', updatedFormData);
      return updatedFormData;
  });
};



const handleSave = async (updatedFormData = formData, source = 'unknown') => {
  if (isSaving) return;
  console.log(`handleSave called from ${source}`);

  try {
      const userId = localStorage.getItem('userId');
      const [mondayStr] = weeks[selectedWeekIndex].split(' - ');
      const monday = new Date(mondayStr);
      const reportDate = new Date(monday);
      reportDate.setDate(reportDate.getDate() + editingCell.dayIndex);
      const formattedReportDate = reportDate.toISOString().split('T')[0];

      const newActivity = {
          userId,
          mga: null,
          agent: editingCell.lagnname,
          reportDate: formattedReportDate,
          rga: null,
          legacy: null,
          tree: null,
          sa: null,
          ga: null,
          agtnum: null,
          clname: null,
          appts: updatedFormData.appts || 0,
          sits: updatedFormData.sits || 0,
          sales: updatedFormData.sales || 0,
          alp: updatedFormData.alp || 0,
          refs: updatedFormData.refs || 0,
          refSits: updatedFormData.refSits || 0,
          refSales: updatedFormData.refSales || 0,
          refAlp: updatedFormData.refAlp || 0,
          refAppts: updatedFormData.refAppts || 0
      };

      console.log('Prepared New Activity for Submission:', newActivity);

      const response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/submitDailyActivity', newActivity);
      console.log('Response:', response.data);
      if (response.data.success) {
          console.log('Creating new entry');
      } else {
          console.error('Error response from server:', response.data.message);
      }

      const responseDailyActivity = await axios.get('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getAllDailyActivity');
      if (responseDailyActivity.data.success) {
          const activityData = responseDailyActivity.data.data.map(activity => ({
              ...activity,
              reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
          }));
          setOriginalDailyActivity(activityData);
          filterDailyActivity(selectedWeekIndex, activityData);
          console.log('Daily activity data updated');
      } else {
          setError('Error fetching updated daily activity data');
      }

      setEditingCell(null);
  } catch (error) {
      console.error('Error saving data:', error);
  } finally {
      setIsSaving(false);
  }
};


  const scrollToCurrentDay = () => {
    const today = new Date();
    const dayIndex = today.getDay() - 1;
    if (dayIndex === 0) return;

    if (tableContainerRef.current) {
      const yesterdayDayIndex = dayIndex - 1;
      const yesterdayHeader = tableContainerRef.current.querySelector(`th[data-day-index="${yesterdayDayIndex}"]`);
      if (yesterdayHeader) {
        const additionalOffset = 171;
        const offset = yesterdayHeader.offsetLeft + yesterdayHeader.offsetWidth - additionalOffset;
        tableContainerRef.current.scrollTo({ left: offset, behavior: 'instant' });
      }
    }
  };

  const handleCancel = () => {
    setEditingCell(null);
    setFormData({});
  };

  const getDailyActivityForAgent = (lagnname, day) => {
    // Adjust the day to match the index from reportDate.getDay()
    const adjustedDay = (day % 7);
    const activity = dailyActivity.find(activity => {
      const reportDate = new Date(activity.reportDate);
      // Logging to see the reportDate and adjusted day
      return activity.agent === lagnname && reportDate.getDay() === adjustedDay;
    });
  
    // Log the activity data being returned
  
    return activity || {
      appts: 'N/A',
      sits: 'N/A',
      sales: 'N/A',
      alp: 'N/A',
      refs: 'N/A',
      refSits: 'N/A',
      refSales: 'N/A',
      refAlp: 'N/A',
      refAppts: 'N/A',
    };
  };

  const reformatAgentName = (name) => {
    if (!name) return '';
    const parts = name.split(' ');
    if (parts.length < 2) return name;
    const [last, first, ...rest] = parts;
    return `${first} ${rest.join(' ')} ${last}`;
  };

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const formatCurrency = (number) => {
    return `$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)}`;
};

const renderAgentRow = (agent) => {
  const [mondayStr] = weeks[selectedWeekIndex].split(' - ');
  const datesOfWeek = getDatesOfWeek(new Date(mondayStr));

  return datesOfWeek.map((date, dayIndex) => {
      const activity = getDailyActivityForAgent(agent.lagnname, dayIndex + 1); // Updated here to match getDay() logic
      const isEditing = editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex;
      const inputKey = `${agent.lagnname}-${dayIndex}-`;

      return (
          <tr
              id={`row-${agent.lagnname}-${dayIndex}`}
              ref={editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex ? editingRowRef : null}
              className={editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex ? 'editing-row' : ''}
              key={`${agent.lagnname}-${dayIndex}`}
          >
              <td className="day-column">{date.toLocaleDateString()}</td>
              {isEditing ? (
                  <>
                      <td className="editing-cell"><input type="text" name="appts" value={formData.appts} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 1} ref={(el) => inputRefs.current[`${inputKey}1`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="sits" value={formData.sits} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 2} ref={(el) => inputRefs.current[`${inputKey}2`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="sales" value={formData.sales} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 3} ref={(el) => inputRefs.current[`${inputKey}3`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="alp" value={formData.alp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 4} ref={(el) => inputRefs.current[`${inputKey}4`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="refs" value={formData.refs} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 5} ref={(el) => inputRefs.current[`${inputKey}5`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="refSits" value={formData.refSits} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 6} ref={(el) => inputRefs.current[`${inputKey}6`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="refSales" value={formData.refSales} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 7} ref={(el) => inputRefs.current[`${inputKey}7`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="refAlp" value={formData.refAlp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 8} ref={(el) => inputRefs.current[`${inputKey}8`] = el} /></td>
                      <td className="editing-cell"><input type="text" name="refAppts" value={formData.refAppts} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 9} ref={(el) => inputRefs.current[`${inputKey}9`] = el} /></td>
                  </>
              ) : (
                  <>
                      <td onClick={() => handleCellClick(agent, dayIndex, 1)}>{activity.appts !== 'N/A' ? formatNumber(activity.appts) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 2)}>{activity.sits !== 'N/A' ? formatNumber(activity.sits) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 3)}>{activity.sales !== 'N/A' ? formatNumber(activity.sales) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 4)}>{activity.alp !== 'N/A' ? formatCurrency(activity.alp) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 5)}>{activity.refs !== 'N/A' ? formatNumber(activity.refs) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 6)}>{activity.refSits !== 'N/A' ? formatNumber(activity.refSits) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 7)}>{activity.refSales !== 'N/A' ? formatNumber(activity.refSales) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 8)}>{activity.refAlp !== 'N/A' ? formatCurrency(activity.refAlp) : 'N/A'}</td>
                      <td onClick={() => handleCellClick(agent, dayIndex, 9)}>{activity.refAppts !== 'N/A' ? formatNumber(activity.refAppts) : 'N/A'}</td>
                  </>
              )}
          </tr>
      );
  });
};


const renderTotalsRow = (dailyActivity) => {
  const totals = dailyActivity.reduce((acc, activity) => {
      acc.appts += parseInt(activity.appts, 10) || 0;
      acc.sits += parseInt(activity.sits, 10) || 0;
      acc.sales += parseInt(activity.sales, 10) || 0;
      acc.alp += parseInt(activity.alp, 10) || 0;
      acc.refs += parseInt(activity.refs, 10) || 0;
      acc.refSits += parseInt(activity.refSits, 10) || 0;
      acc.refSales += parseInt(activity.refSales, 10) || 0;
      acc.refAlp += parseInt(activity.refAlp, 10) || 0;
      acc.refAppts += parseInt(activity.refAppts, 10) || 0;

      console.log('Activity date:', activity.reportDate); // Log the date of each activity being used for totals

      return acc;
  }, {
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      refSits: 0,
      refSales: 0,
      refAlp: 0,
      refAppts: 0,
  });

  console.log('Totals calculated:', totals); // Log the calculated totals

  return (
      <tr className="totals-row">
          <td>Totals</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatNumber(totals.appts)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatNumber(totals.sits)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatNumber(totals.sales)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatCurrency(totals.alp)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatNumber(totals.refs)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatNumber(totals.refSits)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatNumber(totals.refSales)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatCurrency(totals.refAlp)}</td>
          <td style={{ backgroundColor: '#f0f0f0' }}>{formatNumber(totals.refAppts)}</td>
      </tr>
  );
};

  

  if (loading) return <Placeholder />;
  if (error) return <div>{error}</div>;
  if (!data) return <div>No data available</div>;
  

  return (
    <div className="hier-table-container" ref={tableContainerRef}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <label htmlFor="lagnname-select" style={{ marginRight: '10px' }}>Select Agent:</label>
                <select id="lagnname-select" onChange={handleLagnnameChange} value={selectedLagnname}>
                    {lagnnames.map(name => (
                        <option key={name} value={name}>{name}</option>
                    ))}
                </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button className='hier-change-week-left' onClick={handlePreviousWeek} disabled={selectedWeekIndex === weeks.length - 1}>{'<'}</button>
                <select onChange={handleWeekChange} value={weeks[selectedWeekIndex]}>
                    {weeks.map(week => (
                        <option value={week} key={week}>{week}</option>
                    ))}
                </select>
                <button className='hier-change-week-right' onClick={handleNextWeek} disabled={selectedWeekIndex === 0}>{'>'}</button>
            </div>
        </div>
        <table className="hierarchyTable">
            <thead>
                <tr className="second-row">
                    <th>Day</th>
                    <th style={{ backgroundColor: '' }} className="header-appts">Appts</th>
                    <th style={{ backgroundColor: '' }} className="header-sits">Sits</th>
                    <th style={{ backgroundColor: '' }} className="header-sales">Sales</th>
                    <th style={{ backgroundColor: '' }} className="header-alp">ALP</th>
                    <th style={{ backgroundColor: '' }} className="header-refs">Refs</th>
                    <th style={{ backgroundColor: '' }} className="header-refSits">Ref Sits</th>
                    <th style={{ backgroundColor: '' }} className="header-refSales">Ref Sales</th>
                    <th style={{ backgroundColor: '' }} className="header-refAlp">Ref ALP</th>
                    <th style={{ backgroundColor: '' }} className="header-refAppts">Proj Appts</th>
                </tr>
            </thead>
            <tbody>
                {renderAgentRow(data)}
                {renderTotalsRow(dailyActivity)}
            </tbody>
        </table>
        {editingCell && (
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                <button onClick={() => handleSave(formData, 'submit-button')} className="btn" style={{backgroundColor: '#00558c', color: 'white', margin: '2.5px'}}>Submit</button>
                <button onClick={handleCancel} className="btn btn-secondary cancel-button">Cancel</button>
            </div>
        )}
    </div>
);

  
};

export default LoggedInAgentForm;
