import React, { useState, useEffect, useCallback } from 'react';
import OfficialRank from './OfficialRank';
import GAOfficialRank from './GAOfficialRank';
import MGAOfficialRank from './MGAOfficial';
import AllOfficialRank from './AllOfficialRank';
import PowerOfficial from './PowerOfficial';
import MyTrophyCase from '../Trophy/MyTrophyCase';
import '../../Leaderboards.css';
import '../CombinedRank.css';

const stripTimeFromDate = (dateString) => {
    return new Date(dateString.split('T')[0]);
};

const formatOfficialWeeklyDate = (dateString) => {
    const date = stripTimeFromDate(dateString);
    const dayOfWeek = date.getDay();
    const lastSunday = new Date(date);
    lastSunday.setDate(date.getDate() - dayOfWeek);
    const previousMonday = new Date(lastSunday);
    previousMonday.setDate(lastSunday.getDate() - 6);
    return `${previousMonday.toLocaleDateString('en-US')} - ${lastSunday.toLocaleDateString('en-US')}`;
};

const OfficialParent = ({ selectedPeriodTab, setSelectedPeriodTab }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [uniqueOfficialDates, setUniqueOfficialDates] = useState([]);

    useEffect(() => {
        const fetchOfficialDates = async () => {
            try {
                const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getOfficialActivity');
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    const dates = data.data
                        .filter(item => item.REPORT === selectedPeriodTab)
                        .map(item => item.reportdate);

                    const uniqueDates = [...new Set(dates)].sort((a, b) => new Date(b) - new Date(a));
                    setUniqueOfficialDates(uniqueDates);
                    if (uniqueDates.length > 0) {
                        setSelectedDate(uniqueDates[0]); // Select the newest official date by default
                    }
                }
            } catch (err) {
                console.error('Failed to load official dates', err);
            }
        };

        fetchOfficialDates();
    }, [selectedPeriodTab]);

    const handleDateChange = useCallback((direction) => {
        const dateArray = uniqueOfficialDates;
        const currentIndex = dateArray.indexOf(selectedDate);
        const newIndex = direction === 'prev' ? currentIndex + 1 : currentIndex - 1;
        if (newIndex >= 0 && newIndex < dateArray.length) {
            setSelectedDate(dateArray[newIndex]);
        }
    }, [uniqueOfficialDates, selectedDate]);

    const formatDateOption = useCallback((date) => {
        return selectedPeriodTab === 'Weekly Recap'
            ? formatOfficialWeeklyDate(date)
            : selectedPeriodTab === 'MTD Recap'
                ? `MTD as of ${new Date(date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}`
                : `YTD as of ${new Date(date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}`;
    }, [selectedPeriodTab]);

    return (
        <div className="combined-rank-card">
            <div className="header-with-toggle">
                <div className="tabs">
                    {['Weekly Recap', 'MTD Recap', 'YTD Recap'].map(tab => (
                        <React.Fragment key={tab}>
                            <input
                                type="radio"
                                id={tab}
                                name="periodTabToggle"
                                checked={selectedPeriodTab === tab}
                                onChange={() => setSelectedPeriodTab(tab)}
                            />
                            <label htmlFor={tab}>{tab}</label>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="filters-row">
                <div className="date-filter-container">
                    <>
                        <button className="date-nav-button" onClick={() => handleDateChange('prev')}>{'<'}</button>
                        <select className="date-select" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                            {uniqueOfficialDates.map((date, index) => (
                                <option key={index} value={date}>
                                    {formatDateOption(date)}
                                </option>
                            ))}
                        </select>
                        <button className="date-nav-button" onClick={() => handleDateChange('next')}>{'>'}</button>
                    </>
                </div>
            </div>
            <div className="combined-rank-content">
                <div className="left-column">
                    <div className="rank-leaderboard-card">
                        <h5>Top Agents</h5>
                        <AllOfficialRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} />
                    </div>
                    <div className="rank-leaderboard-card">
                        <h5>Top SAs</h5>
                        <OfficialRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} />
                    </div>
                    <div className="rank-leaderboard-card">
                        <h5>Top GAs</h5>
                        <GAOfficialRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} />
                    </div>
                    <div className="rank-leaderboard-card">
                        <h5>Top MGAs</h5>
                        <MGAOfficialRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} />
                    </div>
                </div>
                <div className="right-column">
                    <div className="separate-container">
                        <PowerOfficial selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} />
                    </div>
                    <div className="trophy-case-container">
                        <MyTrophyCase selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfficialParent;
