import React from 'react';
import SendBenefits from './SendBenefits';

const Tools = () => {
    return (
        <div>
        <SendBenefits />
        </div>
    );
};

export default Tools;