import React, { useState } from 'react';
import axios from 'axios';
import './SendBenefits.css';

const SendBenefits = () => {
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [isSale, setIsSale] = useState(false);
const [primaryCoverage, setPrimaryCoverage] = useState({ whole: '0', term: '0', adb: '0', a71: '0', cancer: '0' });
const [spouseCoverage, setSpouseCoverage] = useState({ whole: '0', term: '0', adb: '0', a71: '0', cancer: '0' });
const [childrenCoverage, setChildrenCoverage] = useState({ whole: '0', adb: '0', a71: '0', cancer: '0' });
const [primaryPremium, setPrimaryPremium] = useState('0');
const [spousePremium, setSpousePremium] = useState('0');
const [childrenPremium, setChildrenPremium] = useState('0');
const [totalPremium, setTotalPremium] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const handleSaleChange = (event) => {
        setIsSale(event.target.checked);
        if (!event.target.checked) {
            setPrimaryCoverage({ whole: '0', term: '0', adb: '0', a71: '0', cancer: '0' });
            setSpouseCoverage({ whole: '0', term: '0', adb: '0', a71: '0', cancer: '0' });
            setChildrenCoverage({ whole: '0', adb: '0', a71: '0', cancer: '0' });
            setPrimaryPremium('0');
            setSpousePremium('0');
            setChildrenPremium('0');
            setTotalPremium(0);
        }
    };
    
    const formatCurrency = (value) => {
        return parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };
    

    const calculateTotalPremium = () => {
        const primary = parseFloat(primaryPremium) || 0;
        const spouse = parseFloat(spousePremium) || 0;
        const children = parseFloat(childrenPremium) || 0;
        setTotalPremium(primary + spouse + children);
    };

    const handlePremiumChange = (setter, value) => {
        const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, '')) || 0;
        setter(numericValue);
        calculateTotalPremium();
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        const payload = {
            clientName,
            clientEmail,
            isSale,
            primaryCoverage,
            spouseCoverage,
            childrenCoverage,
            primaryPremium,
            spousePremium,
            childrenPremium,
            totalPremium,
            userEmail: userInfo.email,
            lagnname: userInfo.lagnname
        };
        

        try {
            await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/sendBenefitsEmail', payload);
            alert('Email sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send email.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="send-benefits-container">
            {isLoading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <h2>Send Benefits</h2>
            <form className="send-benefits-form" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label>Client Name:</label>
                        <input 
                            type="text" 
                            value={clientName} 
                            onChange={(e) => setClientName(e.target.value)} 
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Client Email:</label>
                        <input 
                            type="email" 
                            value={clientEmail} 
                            onChange={(e) => setClientEmail(e.target.value)} 
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="form-group checkbox-group">
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isSale} 
                            onChange={handleSaleChange} 
                        />
                        Sale
                    </label>
                </div>
                {isSale && (
                    <>
                        <h3>Primary</h3>
                        <div className="form-row">
                            <div className="form-group equal-width">
                                <label>Whole Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={primaryCoverage.whole} 
                                    onChange={(e) => setPrimaryCoverage({ ...primaryCoverage, whole: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>Term Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={primaryCoverage.term} 
                                    onChange={(e) => setPrimaryCoverage({ ...primaryCoverage, term: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>ADB Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={primaryCoverage.adb} 
                                    onChange={(e) => setPrimaryCoverage({ ...primaryCoverage, adb: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group equal-width">
                                <label>A71 Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={primaryCoverage.a71} 
                                    onChange={(e) => setPrimaryCoverage({ ...primaryCoverage, a71: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>Cancer Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={primaryCoverage.cancer} 
                                    onChange={(e) => setPrimaryCoverage({ ...primaryCoverage, cancer: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Premium:</label>
                            <input 
                                type="number" 
                                value={primaryPremium} 
                                onChange={(e) => handlePremiumChange(setPrimaryPremium, e.target.value)} 
                                className="form-control"
                            />
                        </div>
    
                        <h3>Spouse</h3>
                            <button type="button"className='match-button' style={{marginBottom: '20px'}} onClick={() => setSpouseCoverage({ ...primaryCoverage })}>
                                Match Primary
                            </button>
                        
                        <div className="form-row">
                            <div className="form-group equal-width">
                                <label>Whole Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={spouseCoverage.whole} 
                                    onChange={(e) => setSpouseCoverage({ ...spouseCoverage, whole: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>Term Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={spouseCoverage.term} 
                                    onChange={(e) => setSpouseCoverage({ ...spouseCoverage, term: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>ADB Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={spouseCoverage.adb} 
                                    onChange={(e) => setSpouseCoverage({ ...spouseCoverage, adb: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group equal-width">
                                <label>A71 Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={spouseCoverage.a71} 
                                    onChange={(e) => setSpouseCoverage({ ...spouseCoverage, a71: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>Cancer Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={spouseCoverage.cancer} 
                                    onChange={(e) => setSpouseCoverage({ ...spouseCoverage, cancer: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Premium:</label>
                            <input 
                                type="number" 
                                value={spousePremium} 
                                onChange={(e) => handlePremiumChange(setSpousePremium, e.target.value)} 
                                className="form-control"
                            />
                        </div>
    
                        <h3>Children</h3>
                        <div className="form-row">
                            <div className="form-group equal-width">
                                <label>Whole Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={childrenCoverage.whole} 
                                    onChange={(e) => setChildrenCoverage({ ...childrenCoverage, whole: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>ADB Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={childrenCoverage.adb} 
                                    onChange={(e) => setChildrenCoverage({ ...childrenCoverage, adb: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group equal-width">
                                <label>A71 Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={childrenCoverage.a71} 
                                    onChange={(e) => setChildrenCoverage({ ...childrenCoverage, a71: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group equal-width">
                                <label>Cancer Coverage Amount:</label>
                                <input 
                                    type="number" 
                                    value={childrenCoverage.cancer} 
                                    onChange={(e) => setChildrenCoverage({ ...childrenCoverage, cancer: e.target.value })} 
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Premium:</label>
                            <input 
                                type="number" 
                                value={childrenPremium} 
                                onChange={(e) => handlePremiumChange(setChildrenPremium, e.target.value)} 
                                className="form-control"
                            />
                        </div>
    
                        <h3>Total Premium: ${totalPremium.toFixed(2)}</h3>
                    </>
                )}
                <button type="submit" className="submit-button">Send Benefits</button>
            </form>
        </div>
    );
    
};

export default SendBenefits;
