import React, { useState, useEffect, useMemo } from 'react';
import '../../Leaderboards.css';
import { TailSpin } from 'react-loader-spinner';

const stripTimeFromDate = (dateString) => {
    return new Date(dateString.split('T')[0]);
};

const getMondayOfWeek = (dateString) => {
    const date = stripTimeFromDate(dateString);
    const day = date.getDay();
    const diff = day === 0 ? -6 : 1 - day; // Adjust when day is Sunday
    const monday = new Date(date.setDate(date.getDate() + diff));
    return monday.toISOString().split('T')[0];
};

const formatReportedMTDDate = (dateString) => {
    const date = stripTimeFromDate(dateString);
    const options = { month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace('/', '-'); // Extract only MM/YYYY
};

const formatReportedYTDDate = (dateString) => {
    const date = stripTimeFromDate(dateString);
    return date.getFullYear().toString();
};

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // No decimal places
        maximumFractionDigits: 0  // No decimal places
    }).format(value);
};

const reformatAgtNameWithMGA = (name, mga, clname) => {
    if (!name) return '';

    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];

    const parts = name.split(' ');
    if (parts.length < 2) return name;

    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

    let [last, first, middle, ...rest] = parts.length === 3 && parts[2].length === 1 ? parts : [parts[0], parts[1], ...parts.slice(2)];

    if (suffixes.includes(rest[rest.length - 1])) {
        rest.pop();
    }

    const abbreviatedLast = `${capitalize(last.charAt(0))}.`;

    const formattedName = rest.length > 0 
        ? `${capitalize(first)} ${rest.map(capitalize).join(' ')} ${abbreviatedLast}` 
        : `${capitalize(first)} ${abbreviatedLast}`;

    let displayMGA = mga ? mga.split(' ')[0].toUpperCase() : '';
    if (!mga && (clname === 'MGA' || clname === 'RGA')) {
        displayMGA = last.toUpperCase();
    }

    return (
        <>
            <div>{formattedName}</div>
            <div className="mga-value">{displayMGA}</div>
        </>
    );
};

const AllReportedRank = ({ selectedPeriodTab, selectedDate, activeUsersData, dailyActivityData }) => { // Accept dailyActivityData as a prop
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const agnName = localStorage.getItem('agnName'); 

    useEffect(() => {
        setLoading(false); // Assume data is already available as a prop
    }, [dailyActivityData]);

    
    
    const filteredData = useMemo(() => {
        let filtered = [];

        if (selectedPeriodTab === 'Weekly Recap') {
            const [start, end] = selectedDate.split(' - ');
            filtered = dailyActivityData.filter(item => {
                const date = item.reportDate.split('T')[0];
                return date >= start && date <= end;
            });
        } else if (selectedPeriodTab === 'MTD Recap') {
            filtered = dailyActivityData.filter(item => {
                const date = formatReportedMTDDate(item.reportDate.split('T')[0]);
                return date === selectedDate;
            });
        } else if (selectedPeriodTab === 'YTD Recap') {
            filtered = dailyActivityData.filter(item => {
                const date = formatReportedYTDDate(item.reportDate.split('T')[0]);
                return date === selectedDate;
            });
        }

        const weeklyData = filtered.reduce((acc, item) => {
            if (!acc[item.agent]) acc[item.agent] = { alp: 0, refALP: 0 };
            acc[item.agent].alp += parseFloat(item.alp || 0);
            acc[item.agent].refALP += parseFloat(item.refAlp || 0);
            return acc;
        }, {});

        return weeklyData;
    }, [selectedPeriodTab, selectedDate, dailyActivityData]);

    
    const getRankedData = (data) => {
        const activeUsersSet = new Set(activeUsersData.map(user => user.lagnname));

        const rankedData = Object.keys(data)
            .filter(agent => activeUsersSet.has(agent))
            .map(agent => {
                const alp = data[agent]?.alp || 0;
                const refALP = data[agent]?.refALP || 0;
                const score = alp + (refALP * 2);
                return { AGT: agent, alp, refALP, score };
            }).sort((a, b) => b.score - a.score)
            .map((item, index) => ({ ...item, rank: index + 1 }));

        return rankedData;
    };

    if (loading) {
        return (
            <div className="leaderboard-card table-overlay">
                <div className="loading-overlay">
                    <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={40}
                        width={40}
                    />
                </div>
                <table className="leaderboard-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th className='rank-agent-name'>AGT</th>
                            <th>Score</th>
                            <th>ALP</th>
                            <th>RefALP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Empty rows to maintain table structure during loading */}
                        <tr><td colSpan="5">&nbsp;</td></tr>
                        <tr><td colSpan="5">&nbsp;</td></tr>
                        <tr><td colSpan="5">&nbsp;</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const rankedDailyActivityData = getRankedData(filteredData);

    const renderDailyActivityTableRows = (rankedData) => {
        return rankedData.map((item, index) => {
            const agentData = activeUsersData.find(user => user.lagnname === item.AGT) || {};
            const mga = agentData.mga || '';
            const clname = agentData.clname || '';

            let rankDisplay;
            if (index === 0) {
                rankDisplay = '🥇'; // Gold medal for 1st place
            } else if (index === 1) {
                rankDisplay = '🥈'; // Silver medal for 2nd place
            } else if (index === 2) {
                rankDisplay = '🥉'; // Bronze medal for 3rd place
            } else {
                rankDisplay = item.rank;
            }

            return (
                <tr key={index}>
                    <td className='rank-column-header'>{rankDisplay}</td>
                    <td className='rank-agent-name'>{reformatAgtNameWithMGA(item.AGT, mga, clname)}</td>
                    <td>{item.score}</td>
                    <td>{formatCurrency(item.alp)}</td>
                    <td>{formatCurrency(item.refALP)}</td>
                </tr>
            );
        });
    };

    const renderStickyRow = (rankedData) => {
        const stickyItem = rankedData.find(item => item.AGT === agnName);

        if (!stickyItem) return null;

        let rankDisplay = `${stickyItem.rank}/${rankedData.length}`;

        const agentData = activeUsersData.find(user => user.lagnname === stickyItem.AGT) || {};
        const mga = agentData.mga || '';
        const clname = agentData.clname || '';

        return (
            <table className="sticky-row-table-reactive">
                <tbody>
                    <tr>
                        <td className='rank-column-header'>{rankDisplay}</td>
                        <td className='rank-agent-name'>
                            {reformatAgtNameWithMGA(stickyItem.AGT, mga, clname)}
                        </td>
                        <td>{stickyItem.score}</td>
                        <td>{formatCurrency(stickyItem.alp)}</td>
                        <td>{formatCurrency(stickyItem.refALP)}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    return (
        <div className="ranking-leaderboard-card">
            <table className="reactive-ranking-table">
                <thead>
                    <tr>
                        <th className='rank-column-header'></th>
                        <th className='rank-agent-name'>AGT</th>
                        <th>Score</th>
                        <th>ALP</th>
                        <th>Ref ALP</th>
                    </tr>
                </thead>
                <tbody>
                    {renderDailyActivityTableRows(rankedDailyActivityData)}
                </tbody>
            </table>
            {renderStickyRow(rankedDailyActivityData)}
        </div>
    );
};

export default AllReportedRank;
