import React, { useState, useEffect, useCallback } from 'react';
import ReportedRank from './ReportedRank';
import GAReportedRank from './GAReportedRank';
import MGAReportedRank from './MGAReportedRank';
import AllReportedRank from './AllReportedRank';
import PowerReported from './PowerReported';
import MyTrophyCase from '../Trophy/MyTrophyCase';
import '../../Leaderboards.css';
import '../CombinedRank.css';

const stripTimeFromDate = (dateString) => {
    return new Date(dateString.split('T')[0]);
};

const getMondayOfWeek = (dateString) => {
    const date = stripTimeFromDate(dateString);
    const day = date.getUTCDay();
    const diff = day === 0 ? -6 : 1 - day; // Adjust when day is Sunday to previous Monday
    date.setUTCDate(date.getUTCDate() + diff);
    return date.toISOString().split('T')[0];
};

const getSundayOfWeek = (mondayString) => {
    const monday = stripTimeFromDate(mondayString);
    const sunday = new Date(monday);
    sunday.setUTCDate(monday.getUTCDate() + 6);
    return sunday.toISOString().split('T')[0];
};

const formatReportedMTDDate = (dateString) => {
    const date = stripTimeFromDate(dateString);
    const options = { month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace('/', '-'); // Extract only MM/YYYY
};

const formatReportedYTDDate = (dateString) => {
    const date = stripTimeFromDate(dateString);
    return date.getUTCFullYear().toString();
};

const ReportedParent = ({ selectedPeriodTab, setSelectedPeriodTab }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [uniqueReportedDates, setUniqueReportedDates] = useState([]);
    const [activeUsersData, setActiveUsersData] = useState([]);
    const [dailyActivityData, setDailyActivityData] = useState([]); // State for daily activity data

    useEffect(() => {
        const fetchReportedDates = async () => {
            try {
                const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getUniqueReportDates');
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    const uniqueReportDates = data.data;
        
                    const weeks = uniqueReportDates.reduce((acc, date) => {
                        const monday = getMondayOfWeek(date);
                        const sunday = getSundayOfWeek(monday);
                        const weekRange = `${monday} - ${sunday}`;
                        if (!acc.includes(weekRange)) {
                            acc.push(weekRange);
                        }
                        return acc;
                    }, []);
                    const today = new Date();

                    const currentMonth = today.toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' }).replace('/', '-');

                    const uniqueMonths = [...new Set(uniqueReportDates.map(date => formatReportedMTDDate(date)))].sort((a, b) => new Date(b) - new Date(a));
                    const uniqueYears = [...new Set(uniqueReportDates.map(date => formatReportedYTDDate(date)))].sort((a, b) => new Date(b) - new Date(a));
        
                    weeks.sort((a, b) => new Date(b.split(' - ')[0]) - new Date(a.split(' - ')[0]));
        
                    if (selectedPeriodTab === 'Weekly Recap') {
                        setUniqueReportedDates(weeks);
                        setSelectedDate(weeks[0]);
                    } else if (selectedPeriodTab === 'MTD Recap') {
                        setUniqueReportedDates(uniqueMonths);
                        const defaultMonth = uniqueMonths.includes(currentMonth) ? currentMonth : uniqueMonths[0];
                        setSelectedDate(defaultMonth);                    } else if (selectedPeriodTab === 'YTD Recap') {
                        setUniqueReportedDates(uniqueYears);
                        setSelectedDate(uniqueYears[0]);
                    }
                }
            } catch (err) {
                console.error('Failed to load reported dates', err);
            }
        };
        

        const fetchActiveUsers = async () => {
            try {
                const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getActiveUsers');
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    setActiveUsersData(data.data);
                } else {
                    console.error('Failed to load active users data');
                }
            } catch (err) {
                console.error('Failed to load active users data', err);
            }
        };

        fetchReportedDates();
        fetchActiveUsers();
    }, [selectedPeriodTab]);

    useEffect(() => {
        const fetchDailyActivityInRange = async () => {
            let start, end;
    
            try {
                if (selectedPeriodTab === 'MTD Recap') {
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = today.getMonth() + 1; // getMonth() is zero-indexed
    
                    // First day of the current month
                    const firstDayOfMonth = new Date(year, month - 1, 1);
                    // Last day of the current month
                    const lastDayOfMonth = new Date(year, month, 0);
    
    
                    if (isNaN(firstDayOfMonth) || isNaN(lastDayOfMonth)) {
                        throw new Error('Invalid MTD date');
                    }
    
                    start = firstDayOfMonth.toISOString().split('T')[0];
                    end = lastDayOfMonth.toISOString().split('T')[0];

                } else if (selectedPeriodTab === 'YTD Recap') {
                    const year = selectedDate;
                    const firstDayOfYear = new Date(`${year}-01-01`);
                    const lastDayOfYear = new Date(`${year}-12-31`);
    
                    if (isNaN(firstDayOfYear) || isNaN(lastDayOfYear)) {
                        throw new Error('Invalid YTD date');
                    }
    
                    start = firstDayOfYear.toISOString().split('T')[0];
                    end = lastDayOfYear.toISOString().split('T')[0];
                } else {
                    // For Weekly Recap, the date range is already split into start and end
                    [start, end] = selectedDate.split(' - ');
                    const startDate = new Date(start);
                    const endDate = new Date(end);
    
                    if (isNaN(startDate) || isNaN(endDate)) {
                        throw new Error('Invalid Weekly date range');
                    }
    
                    start = startDate.toISOString().split('T')[0];
                    end = endDate.toISOString().split('T')[0];
                }
    
                const response = await fetch(`https://morning-sands-14889-102dd928c446.herokuapp.com/api/getDailyActivityInRange?start=${start}&end=${end}`);
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    setDailyActivityData(data.data);
                } else {
                    setDailyActivityData([]); // Clear data if no activity found
                }
            } catch (err) {
                console.error('Error fetching daily activity data in range', err);
                setDailyActivityData([]); // Clear data on error
            }
        };
    
        if (selectedDate) {
            fetchDailyActivityInRange();
        }
    }, [selectedDate, selectedPeriodTab]);
    
    
    

    const handleDateChange = useCallback((direction) => {
        const dateArray = uniqueReportedDates;
        const currentIndex = dateArray.indexOf(selectedDate);
        const newIndex = direction === 'prev' ? currentIndex + 1 : currentIndex - 1;
        if (newIndex >= 0 && newIndex < dateArray.length) {
            setSelectedDate(dateArray[newIndex]);
        }
    }, [uniqueReportedDates, selectedDate]);

    const formatDateOption = useCallback((date) => {
        return date;
    }, []);

    return (
        <div className="combined-rank-card">
            <div className="header-with-toggle">
                <div className="tabs">
                    {['Weekly Recap', 'MTD Recap', 'YTD Recap'].map(tab => (
                        <React.Fragment key={tab}>
                            <input
                                type="radio"
                                id={tab}
                                name="periodTabToggle"
                                checked={selectedPeriodTab === tab}
                                onChange={() => setSelectedPeriodTab(tab)}
                            />
                            <label htmlFor={tab}>{tab}</label>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="filters-row">
                <div className="date-filter-container">
                    <>
                        <button className="date-nav-button" onClick={() => handleDateChange('prev')}>{'<'}</button>
                        <select className="date-select" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                            {uniqueReportedDates.map((date, index) => (
                                <option key={index} value={date}>
                                    {formatDateOption(date)}
                                </option>
                            ))}
                        </select>
                        <button className="date-nav-button" onClick={() => handleDateChange('next')}>{'>'}</button>
                    </>
                </div>
            </div>
            <div className="combined-rank-content">
                <div className="left-column">
                    <div className="rank-leaderboard-card">
                        <h5>Top Agents</h5>
                        <AllReportedRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} activeUsersData={activeUsersData} dailyActivityData={dailyActivityData} />
                    </div>
                    <div className="rank-leaderboard-card">
                        <h5>Top SAs</h5>
                        <ReportedRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} activeUsersData={activeUsersData} dailyActivityData={dailyActivityData} />
                    </div>
                    <div className="rank-leaderboard-card">
                        <h5>Top GAs</h5>
                        <GAReportedRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} activeUsersData={activeUsersData} dailyActivityData={dailyActivityData} />
                    </div>
                    <div className="rank-leaderboard-card">
                        <h5>Top MGAs</h5>
                        <MGAReportedRank selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} activeUsersData={activeUsersData} dailyActivityData={dailyActivityData} />
                    </div>
                </div>
                <div className="right-column">
                    <div className="separate-container">
                        <PowerReported selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} activeUsersData={activeUsersData} dailyActivityData={dailyActivityData} />
                    </div>
                    <div className="trophy-case-container">
                        <MyTrophyCase selectedPeriodTab={selectedPeriodTab} selectedDate={selectedDate} activeUsersData={activeUsersData} dailyActivityData={dailyActivityData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportedParent;
