import React, { useState, useEffect } from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard/Dashboard';
import Production from './components/Production/Production';
import Login from './components/Account/Login';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AgentSetupPage from './components/Account/AgentSetup';
import Register from './components/Register';
import Account from './components/Account/Account';
import { initGA, usePageTracking } from './components/utils/analytics'; // Adjust the import path as necessary
import TrainingMaterials from './components/Resources/TrainingMaterials';
import ImportantWebsites from './components/Resources/ImportantWebsites';
import Underwriting from './components/Resources/Underwriting';
import AdditionalResources from './components/Resources/AdditionalResources';
import ActiveUsersTable from './components/ActiveUsers/ActiveUsersTable';
import Manage from './components/Management/Manage';
import Tools from './components/Tools/Tools';

function App() {
    const [currentPage, setCurrentPage] = useState("");
    const [currentSection, setCurrentSection] = useState(""); // Initialize currentSection state
    const location = useLocation();

    useEffect(() => {
        // Initialize Google Analytics on app mount
        initGA();
    }, []);

    usePageTracking(); // Track page views

    useEffect(() => {
        // Function to set the current page based on the route
        const routeToPageName = (path) => {
            switch(path) {
                case '/login': return 'LoginPage';
                case '/register': return 'Register';
                case '/agent-setup': return 'Agent Setup';
                case '/account': return 'Account';
                case '/': return 'Leaderboards';
                case '/production': return 'Reports';
                case '/tools': return 'Tools';
                case '/trainingMaterials': return 'Training Materials';
                case '/importantWebsites': return 'Important Websites';
                case '/underwriting': return 'Underwriting';
                case '/additionalResources': return 'Additional Resources';

                default: return '';
            }
        };

        const routeToSectionName = (path) => {
            switch(path) {
                case '/': return 'leaderboards';
                case '/production': return 'reports';
                case '/trainingMaterials': return 'trainingMaterials';
                case '/importantWebsites': return 'importantWebsites';
                case '/underwriting': return 'underwriting';
                case '/additionalResources': return 'additionalResources';
                case '/account': return 'account';
                case '/tools': return 'tools';
                default: return '';
            }
        };

        setCurrentPage(routeToPageName(location.pathname)); // Set page name based on current route
        setCurrentSection(routeToSectionName(location.pathname)); // Set section name based on current route
    }, [location]);

    // Retrieve userId and agnName from localStorage
    const userId = localStorage.getItem('userId');
    const agnName = localStorage.getItem('agnName');

    // Condition to check if the user is Trainee
    const isTrainee = userId === '100000000000000' || agnName === 'Trainee';

    return (
        <>
            <Navbar currentPage={currentPage} currentSection={currentSection} setCurrentSection={setCurrentSection} />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/agent-setup" element={<AgentSetupPage />} />
                <Route path="/trainingMaterials" element={<ProtectedRoute element={TrainingMaterials} />} />
                <Route path="/importantWebsites" element={<ProtectedRoute element={ImportantWebsites} />} />
                <Route path="/underwriting" element={<ProtectedRoute element={Underwriting} />} />
                <Route path="/additionalResources" element={<ProtectedRoute element={AdditionalResources} />} />
                <Route path="/manage" element={<ProtectedRoute element={Manage} />} />
                <Route path="/tools" element={<ProtectedRoute element={Tools} />} />
                <Route path="*" element={<Navigate to={isTrainee ? "/trainingMaterials" : "/"} replace />} />
                {!isTrainee && (
                    <>
                        <Route path="/" element={<ProtectedRoute element={Dashboard} />} />
                        <Route path="/production" element={<ProtectedRoute element={Production} />} />
                        <Route path="/account" element={<ProtectedRoute element={Account} />} />
                    </>
                )}
            </Routes>
        </>
    );
}

export default App;
