import React, { useState, useRef } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { FaUser, FaSignOutAlt } from 'react-icons/fa'; // Import icons
import logo from '../img/globe1.png';
import '../App.css';
import './Navbar.css';

function CustomNavbar({ currentPage, currentSection, setCurrentSection }) {
    const navigate = useNavigate();
    const location = useLocation();
    const popupWindows = useRef([]); // Use ref to persist popup windows across renders

    const [agentDropdownOpen, setAgentDropdownOpen] = useState(false);
    const [leadershipDropdownOpen, setLeadershipDropdownOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userId');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('agnName');
        localStorage.removeItem('mgaRgaData');
        localStorage.removeItem('selectedAgent');
        localStorage.removeItem('selectedMGA');
        navigate('/login');
    };

    const openPres = (presFile) => {
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
        const width = screenWidth > 3800 ? Math.round(screenWidth * 0.35) : Math.round(screenWidth * 0.7);
        const height = Math.round(screenHeight * 0.7);
        const left = screenWidth > 3800 ? Math.round((screenWidth - width) / 2.61) : 0;
        const top = screenHeight - height;

        let openedWindow = null;
        for (const win of popupWindows.current) {
            if (!win.closed) {
                openedWindow = win;
                break;
            }
        }

        if (openedWindow) {
            openedWindow.location.href = presFile;
            openedWindow.focus();
            openedWindow.moveToTop();
        } else {
            const presWindow = window.open(
                presFile,
                '_blank',
                `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
            );
            popupWindows.current.push(presWindow);
            presWindow.focus();
        }
    };

    const openNormalTab = (url) => {
        window.open(url, '_blank');
    };
    

    if (location.pathname === '/login' || location.pathname === '/agentsignup') {
        return null;
    }

    const userId = localStorage.getItem('userId');
    const agnName = localStorage.getItem('agnName');

    const isTrainee = userId === '100000000000000' || agnName === 'Trainee';

    return (
        <Navbar className="navbar-sticky" expand="lg">
            <Container className="navbar-container">
                <Navbar.Brand href="/" className="navbar-brand">
                    <img src={logo} className="navbar-brand-custom" alt="Logo" style={{height: '50px'}} />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto secondary-navbar">
                        <LinkContainer to="/">
                            <Nav.Link onClick={() => setCurrentSection('leaderboards')} active={currentSection === 'leaderboards'}>
                                Leaderboards
                            </Nav.Link>
                        </LinkContainer>
                        {!isTrainee && (
                            <>
                                <LinkContainer to="/production">
                                    <Nav.Link onClick={() => setCurrentSection('reports')} active={currentSection === 'reports'}>
                                        Reports
                                    </Nav.Link>
                                </LinkContainer>
                            </>
                        )}
                        <NavDropdown
                            title="Agent Tools"
                            id="agent-tools-dropdown"
                            show={agentDropdownOpen}
                            onMouseEnter={() => setAgentDropdownOpen(true)}
                            onMouseLeave={() => setAgentDropdownOpen(false)}
                        >
                            <LinkContainer to="/trainingMaterials">
                                <NavDropdown.Item onClick={() => setCurrentSection('trainingMaterials')} active={currentSection === 'trainingMaterials'}>
                                    Training Materials
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/importantWebsites">
                                <NavDropdown.Item onClick={() => setCurrentSection('importantWebsites')} active={currentSection === 'importantWebsites'}>
                                    Important Websites
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/underwriting">
                                <NavDropdown.Item onClick={() => setCurrentSection('underwriting')} active={currentSection === 'underwriting'}>
                                    Underwriting
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/additionalResources">
                                <NavDropdown.Item onClick={() => setCurrentSection('additionalResources')} active={currentSection === 'additionalResources'}>
                                    Additional Resources
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/tools">
                                <NavDropdown.Item onClick={() => setCurrentSection('tools')} active={currentSection === 'tools'}>
                                    Send Benefits
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={() => openPres('https://legacyail.com/agent_tools/presentation/pres_setup.html?team=team-arturo')}>
                                Presentation Launcher
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => openNormalTab('https://legacyail.com/phone_scripts/phones.html')}>
                                Open Phone Scripts
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            title="Leadership Tools"
                            id="leadership-tools-dropdown"
                            show={leadershipDropdownOpen}
                            onMouseEnter={() => setLeadershipDropdownOpen(true)}
                            onMouseLeave={() => setLeadershipDropdownOpen(false)}
                        >
                            <NavDropdown.Item href="https://legacyail.com/agent_tools/leadership_tools/Leadership_Track.pdf#view=Fit" target="_blank">
                                Leadership Track
                            </NavDropdown.Item>
                        </NavDropdown>
                        {!isTrainee && (
                            <LinkContainer to="/account">
                                <Nav.Link onClick={() => setCurrentSection('account')} active={currentSection === 'account'} className="icon-button">
                                    <FaUser />
                                </Nav.Link>
                            </LinkContainer>
                        )}
                        <Nav.Link onClick={handleLogout} className="icon-button">
                            <FaSignOutAlt />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;
