import React, { useState, useEffect, useMemo, useCallback } from 'react';
import '../../Leaderboards.css';
import { TailSpin } from 'react-loader-spinner';

const formatCurrentWeeklyDateRange = (dateString) => {
    const date = new Date(dateString);
    const startOfWeek = new Date(date);
    const endOfWeek = new Date(date);

    // Set to start of the week (Monday)
    startOfWeek.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
    startOfWeek.setHours(0, 0, 0, 0);

    // Set to end of the week (Sunday)
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedStartOfWeek = startOfWeek.toLocaleDateString('en-US', options);
    const formattedEndOfWeek = endOfWeek.toLocaleDateString('en-US', options);

    return `Week of ${formattedStartOfWeek} - ${formattedEndOfWeek}`;
};

const formatReportedMTDDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace('/', '-'); // Extract only MM/YYYY
};

const formatReportedYTDDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear().toString();
};

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // No decimal places
        maximumFractionDigits: 0  // No decimal places
    }).format(value);
};

const reformatSANameWithMGA = (name, mga) => {
    if (!name) return '';

    // List of common suffixes
    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];

    const parts = name.split(' ');
    if (parts.length < 2) return name;

    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

    // Assuming that a middle initial will be a single character
    let [last, first, middle, ...rest] = parts.length === 3 && parts[2].length === 1 ? parts : [parts[0], parts[1], ...parts.slice(2)];

    // Check for and remove suffix if present
    if (suffixes.includes(rest[rest.length - 1])) {
        rest.pop();
    }

    const abbreviatedLast = `${capitalize(last.charAt(0))}.`;

    const formattedName = rest.length > 0 
        ? `${capitalize(first)} ${rest.map(capitalize).join(' ')} ${abbreviatedLast}` 
        : `${capitalize(first)} ${abbreviatedLast}`;

    // Determine the MGA value
    let displayMGA = mga ? mga.split(' ')[0].toUpperCase() : '';

    return (
        <>
            <div>{formattedName}</div>
            <div className="mga-value">{displayMGA}</div>
        </>
    );
};

const ReportedRank = ({ selectedPeriodTab, selectedDate, activeUsersData, dailyActivityData }) => { // Accept dailyActivityData as a prop
    const [saData, setSaData] = useState([]);
    const [processedData, setProcessedData] = useState({ weekly: {}, monthly: {}, yearly: {} });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSAData = async () => {
            setLoading(true);
            try {
                const saResponse = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/saHierarchy');
                const saData = await saResponse.json();

                if (saData.success && Array.isArray(saData.data)) {
                    const sixMonthsAgo = new Date();
                    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

                    const filteredData = saData.data.map(saItem => {
                        const filteredAGTs = saItem.AGTs.split('-- ').filter(agent => {
                            const esid = agent.split(' - ')[2];
                            const esidDate = new Date(esid.split(' ')[0]);
                            return esidDate >= sixMonthsAgo;
                        }).join(';');

                        return { ...saItem, AGTs: filteredAGTs || 'No valid AGTs' };
                    });

                    setSaData(filteredData);
                } else {
                    setError('Failed to load SA data');
                }

                setLoading(false);
            } catch (err) {
                setError('Failed to load data');
                setLoading(false);
            }
        };

        fetchSAData();
    }, []);

    

    const processReportedData = useCallback((saData, dailyActivityData) => {
        const data = {
            weekly: {},
            monthly: {},
            yearly: {}
        };
    
        const weekKeys = new Set(dailyActivityData.map(item => formatCurrentWeeklyDateRange(item.reportDate.split('T')[0])));
        const monthKeys = new Set(dailyActivityData.map(item => formatReportedMTDDate(item.reportDate.split('T')[0])));
        const yearKeys = new Set(dailyActivityData.map(item => formatReportedYTDDate(item.reportDate.split('T')[0])));
    
        saData.forEach(saItem => {
            weekKeys.forEach(week => {
                if (!data.weekly[week]) data.weekly[week] = {};
                if (!data.weekly[week][saItem.SA]) data.weekly[week][saItem.SA] = { alp: 0 };
            });
    
            monthKeys.forEach(month => {
                if (!data.monthly[month]) data.monthly[month] = {};
                if (!data.monthly[month][saItem.SA]) data.monthly[month][saItem.SA] = { alp: 0 };
            });
    
            yearKeys.forEach(year => {
                if (!data.yearly[year]) data.yearly[year] = {};
                if (!data.yearly[year][saItem.SA]) data.yearly[year][saItem.SA] = { alp: 0 };
            });
        });
    
        dailyActivityData.forEach(item => {
            const week = formatCurrentWeeklyDateRange(item.reportDate.split('T')[0]);
            const month = formatReportedMTDDate(item.reportDate.split('T')[0]);
            const year = formatReportedYTDDate(item.reportDate.split('T')[0]);
    
            saData.forEach(saItem => {
                const agentNames = saItem.AGTs.split(';').map(agent => agent.split(' - ')[0].trim());
    
                agentNames.forEach(agent => {
                    if (agent === item.agent) {
                        data.weekly[week][saItem.SA].alp += parseFloat(item.alp || 0);
                        data.monthly[month][saItem.SA].alp += parseFloat(item.alp || 0);
                        data.yearly[year][saItem.SA].alp += parseFloat(item.alp || 0);
                    }
                });
            });
        });
    
        return data;
    }, []);

    useEffect(() => {
        if (saData.length > 0 && dailyActivityData.length > 0) {
            const processed = processReportedData(saData, dailyActivityData);
            setProcessedData(processed);
        }
    }, [saData, dailyActivityData, processReportedData]);


    const currentData = useMemo(() => {
        if (selectedPeriodTab === 'Weekly Recap') {
            const [start, end] = selectedDate.split(' - ');
            const weeklyData = dailyActivityData
                .filter(item => {
                    const date = item.reportDate.split('T')[0];
                    return date >= start && date <= end;
                })
                .reduce((acc, item) => {
                    saData.forEach(saItem => {
                        const agentNames = saItem.AGTs.split(';').map(agent => agent.split(' - ')[0].trim());
                        agentNames.forEach(agent => {
                            if (agent === item.agent) {
                                if (!acc[saItem.SA]) acc[saItem.SA] = { alp: 0 };
                                acc[saItem.SA].alp += parseFloat(item.alp || 0);
                            }
                        });
                    });
                    return acc;
                }, {});

            return weeklyData;
        } else if (selectedPeriodTab === 'MTD Recap') {
            const monthlyData = processedData.monthly[selectedDate] || {};

            saData.forEach(saItem => {
                if (!monthlyData[saItem.SA]) {
                    monthlyData[saItem.SA] = { alp: 0 };
                }
            });

            return monthlyData;
        } else if (selectedPeriodTab === 'YTD Recap') {
            const yearlyData = processedData.yearly[selectedDate] || {};

            saData.forEach(saItem => {
                if (!yearlyData[saItem.SA]) {
                    yearlyData[saItem.SA] = { alp: 0 };
                }
            });

            return yearlyData;
        }
    }, [selectedPeriodTab, selectedDate, dailyActivityData, processedData, saData]);

    
    const getRankedData = useCallback((data) => {
        const activeUsersSet = new Set(activeUsersData.map(user => user.lagnname));
    
        const rankedData = saData
            .filter(saItem => activeUsersSet.has(saItem.SA))
            .map(saItem => {
                const alp = data[saItem.SA]?.alp || 0;
                return { SA: saItem.SA, MGA: saItem.MGA, alp };
            })
            .sort((a, b) => b.alp - a.alp)
            .map((item, index) => ({ ...item, rank: index + 1 }));
    
        return rankedData.slice(0, 3); // Limit to top 3 entries
    }, [activeUsersData, saData]);
    

        const rankedDailyActivityData = useMemo(() => getRankedData(currentData), [currentData, getRankedData]);

    const renderDailyActivityTableRows = (rankedData) => {
        return rankedData.map((item, index) => {
            const agentData = activeUsersData.find(user => user.lagnname === item.SA) || {};
            const mga = agentData.mga || '';
            const clname = agentData.clname || '';
    
            let rankDisplay;
            if (index === 0) {
                rankDisplay = '🥇';
            } else if (index === 1) {
                rankDisplay = '🥈';
            } else if (index === 2) {
                rankDisplay = '🥉';
            } else {
                rankDisplay = item.rank;
            }
    
            return (
                <tr key={index}>
                    <td className='rank-column-header'>{rankDisplay}</td>
                    <td className='rank-agent-name'>{reformatSANameWithMGA(item.SA, mga)}</td>
                    <td>{formatCurrency(item.alp)}</td>
                </tr>
            );
        });
    };
    

    if (loading) {
        return (
            <div className="leaderboard-card table-overlay">
                <div className="loading-overlay">
                    <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={40}
                        width={40}
                    />
                </div>
                <table className="reactive-ranking-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>SA</th>
                            <th>ALP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="leaderboard-card">
            <table className="ranking-table">
                <thead>
                    <tr>
                        <th className='rank-column-header'></th>
                        <th>SA</th>
                        <th>ALP</th>
                    </tr>
                </thead>
                <tbody>
                    {renderDailyActivityTableRows(rankedDailyActivityData)}
                </tbody>
            </table>
        </div>
    );
};

export default ReportedRank;
