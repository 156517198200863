import React from 'react';
import './GridContent.css';

const Underwriting = () => {
  return (
    <div className="grid-content" id="underwriting">
      <div className="grid-cat">
        <div className="category">Underwriting</div>
        <div className="category-link"><a href="https://drive.google.com/drive/folders/1YbeyGB7_s9HowprC9vT12OLbJ4vg1dRX?usp=drive_link" target="_blank" rel="noopener noreferrer">Underwriting <img src="/img/Google_Drive_icon_2020.svg.png" alt="Google Drive" style={{ verticalAlign: 'baseline' }} /></a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Auto-Trial Sheet 04-2024.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Auto Trial List 2024</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/uw/UW-Field-Manual.pdf#view=Fit" target="_blank" rel="noopener noreferrer">UW Field Manual</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/uw/UW-Height-Weight-Decline.pdf#view=Fit" target="_blank" rel="noopener noreferrer">UW Height Weight Decline</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/uw/AccidentalDeathBenefitsLimits.pdf#view=Fit" target="_blank" rel="noopener noreferrer">ADB Limits</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/uw/Child Policy Guidelines.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Child Policy Guidelines</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/uw/What is Controlled Biz.pdf#view=Fit" target="_blank" rel="noopener noreferrer">What is Controlled Business?</a></div>
      </div>
    </div>
  );
};

export default Underwriting;
