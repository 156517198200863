import React from 'react';
import './GridContent.css';

const AdditionalResources = () => {
  return (
    <div className="grid-content" id="additionalResources">
      <div className="grid-cat">
        <div className="category">Additional Resources</div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/PLAYBOOK  revised 10-5-23.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Agent Playbook</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/misc/Rate-Book-1.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Rate Book</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Cancer Rates (CNM).pdf#view=Fit" target="_blank" rel="noopener noreferrer">Cancer Rates</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/closing notes (002).pdf#view=Fit" target="_blank" rel="noopener noreferrer">Closing Notes</a></div>
        <div className="category-link"><a href="https://drive.google.com/drive/folders/1JqTDEjPDAjQqn3tDCulZG0lPJ8qWLUrn" target="_blank" rel="noopener noreferrer">Helpful How to Videos</a></div>
        <div className="category-link"><a href="https://forms.gle/SqpvrjzsmFnRkXoV7" target="_blank" rel="noopener noreferrer">POS Quiz</a></div>
      </div>
      <div className="grid-cat">
        <div className="category">Additional Sales Slides</div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/12. Family 2 Job (All Benefits).pdf#view=Fit" target="_blank" rel="noopener noreferrer">Family Two Job</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/15. Single 2 Job.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Single Two Job</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/14. Cancer 3 Option_.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Cancer 3 Options</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/Cancer Facts.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Cancer Facts</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/Cancer claim example.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Cancer Claim Example</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/Family LOC.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Family List of Concerns</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/Senior LOC.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Senior List of Concerns</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/Singe LOC.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Single List of Concerns</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/21. Projected-Funeral-Cost.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Projected Funeral Cost</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/20. Projected-Cremation-Cost.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Projected Cremation Cost</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/additional_sales_slides/9. Gerber vs AIL Headstart.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Gerber vs. AIL Headstart</a></div>
        <div className="category-link"><a href="https://drive.google.com/drive/folders/1RE-Kh6NCx3pZJBUXv55ytl6M37O3o1kT" target="_blank" rel="noopener noreferrer">PowerPoint Slides</a></div>
      </div>
      <div className="grid-cat">
        <div className="category">Post Presentation Email PDFs</div>
        <div className="category-link"><strong>Sales:</strong></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/QR Checklist.pdf#view=Fit" target="_blank" rel="noopener noreferrer">QR Checklist Super Combo</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/QR Checklist for Seniors.pdf#view=Fit" target="_blank" rel="noopener noreferrer">QR Checklist Senior</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Policy_Holder_Packet.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Policy Holder Packet</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Important_Documents.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Important Documents</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/AD&D Certificate.pdf#view=Fit" target="_blank" rel="noopener noreferrer">AD&D</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/AIL Plus.pdf#view=Fit" target="_blank" rel="noopener noreferrer">AIL Plus</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Child Safe Kit.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Child Safe Kit</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Will-Kit.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Will Kit</a></div>
        <br />
        <div className="category-link"><strong>Non-Sales:</strong></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/AD&D Certificate.pdf#view=Fit" target="_blank" rel="noopener noreferrer">AD&D</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/AIL Plus.pdf#view=Fit" target="_blank" rel="noopener noreferrer">AIL Plus</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Child Safe Kit.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Child Safe Kit</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Last Will & Testament Kit.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Will Kit</a></div>
      </div>
      <div className="grid-cat">
        <div className="category">Misc</div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/AIL DocuSign changes (SMS)_User Instructions.pdf#view=Fit" target="_blank" rel="noopener noreferrer">DocuSign Instructions</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/misc/Policy-Service-Request-1.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Policy Service Request</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/misc/impact_mobile_and_desktop_manual.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Impact Mobile and Desktop</a></div>
        <div className="category-link"><a href="https://www.youtube.com/watch?v=C4sptqFb0Bk" target="_blank" rel="noopener noreferrer">How to Share Your Screen on Zoom</a></div>
        <div className="category-link"><a href="https://www.youtube.com/watch?v=9wjrQAAi0DU" target="_blank" rel="noopener noreferrer">How to Record to the Cloud on Zoom</a></div>
      </div>
    </div>
  );
};

export default AdditionalResources;
