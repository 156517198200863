import React, { useState } from 'react';
import ReportedParent from './ReportedParent';
import OfficialParent from './OfficialParent';
import '../../Leaderboards.css';
import '../CombinedRank.css';

const CombinedRank = () => {
    const [selectedDataTab, setSelectedDataTab] = useState('Reported');
    const [selectedPeriodTab, setSelectedPeriodTab] = useState('Weekly Recap');

    return (
        <div className="combined-rank-card">
            <div className="header-with-toggle">
                <div className="tabs">
                    {['Reported', 'Official'].map(tab => (
                        <React.Fragment key={tab}>
                            <input
                                type="radio"
                                id={tab}
                                name="dataTabToggle"
                                checked={selectedDataTab === tab}
                                onChange={() => setSelectedDataTab(tab)}
                            />
                            <label htmlFor={tab}>{tab}</label>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {selectedDataTab === 'Reported' ? (
                <ReportedParent selectedPeriodTab={selectedPeriodTab} setSelectedPeriodTab={setSelectedPeriodTab} />
            ) : (
                <OfficialParent selectedPeriodTab={selectedPeriodTab} setSelectedPeriodTab={setSelectedPeriodTab} />
            )}
        </div>
    );
};

export default CombinedRank;
