import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HierarchyComponent.css';
import Placeholder from '../utils/Placeholder';

const ActiveUsersTable = () => {
  const [data, setData] = useState([]);
  const [originalDailyActivity, setOriginalDailyActivity] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const [editingCell, setEditingCell] = useState(null);
  const [formData, setFormData] = useState({});
  const previousCell = useRef(null); // Reference to store the previous cell being edited
  const inputRefs = useRef({}); // Reference to store input elements
  const [allowedAgentNumbers, setAllowedAgentNumbers] = useState([]); // Store allowed agent numbers
  const editingRowRef = useRef(null); // Declare and initialize editingRowRef
  const [isSaving, setIsSaving] = useState(false);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const userId = localStorage.getItem('userId'); // Get userId from localStorage
          const responseRGA = await axios.get('https://morning-sands-14889-102dd928c446.herokuapp.com/api/rgaHierarchy', {
            params: { userId }
          });
          const responseDailyActivity = await axios.get('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getAllDailyActivity');
      
          if (responseRGA.data.success && responseDailyActivity.data.success) {
            console.log("Fetched Data:", responseRGA.data.data); // Log fetched data
            setData(responseRGA.data.data);
            const activityData = responseDailyActivity.data.data.map(activity => ({
              ...activity,
              reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
            }));
            setOriginalDailyActivity(activityData);
            const uniqueWeeks = getUniqueWeeks(activityData);
            setWeeks(uniqueWeeks);
            const newestWeekIndex = 0; // The newest week is at the start of the sorted array
            setSelectedWeekIndex(newestWeekIndex); // Set the newest week as default
          } else {
            setError('No data found');
          }
        } catch (error) {
          setError('Error fetching data');
        } finally {
          setLoading(false);
        }
      };
      

    fetchData();
  }, []);

  useEffect(() => {
    if (weeks.length > 0) {
      filterDailyActivity(selectedWeekIndex, originalDailyActivity);
    }
  }, [selectedWeekIndex, weeks, originalDailyActivity]);

  useEffect(() => {
    if (editingCell) {
      const { lagnname, dayIndex, columnIndex } = editingCell;
      const inputKey = `${lagnname}-${dayIndex}-${columnIndex}`;
      if (inputRefs.current[inputKey]) {
        inputRefs.current[inputKey].focus();
        inputRefs.current[inputKey].select();
      }
    }
  }, [editingCell]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editingCell && editingRowRef.current && !editingRowRef.current.contains(event.target)) {
        if (!isSaving) { // Check if saving is not already in progress
          console.log('Click outside detected, calling handleSave');
          setIsSaving(true); // Set isSaving to true before calling handleSave to prevent multiple calls
          setFormData((prevFormData) => {
            handleSave(prevFormData, 'handleClickOutside'); // Pass source as argument
            return prevFormData;
          });
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      console.log('Cleaning up event listener');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingCell, isSaving]);
  
  
  
  useEffect(() => {
    const fetchHierarchyInfo = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get userId from localStorage
        const response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getHierarchyInfo', {
          userId,
        });
        if (response.data.success) {
          const allowedAgents = response.data.data.map(item => item.lagnname);
          setAllowedAgentNumbers(allowedAgents);
        } else {
          console.error('Failed to fetch hierarchy info');
        }
      } catch (error) {
        console.error('Error fetching hierarchy info:', error);
      }
    };

    fetchHierarchyInfo();
  }, []);

  useEffect(() => {
    if (weeks.length > 0) {
      filterDailyActivity(selectedWeekIndex, originalDailyActivity);
      const [mondayStr, sundayStr] = weeks[selectedWeekIndex].split(' - ');
      const monday = new Date(mondayStr);
      const sunday = new Date(sundayStr);
      const today = new Date();
      if (today >= monday && today <= sunday) {
        scrollToCurrentDay();
      }
    }
  }, [selectedWeekIndex, weeks, originalDailyActivity]);
  
  

  const getUniqueWeeks = (data) => {
    const weeks = {};
    data.forEach(item => {
      const date = new Date(item.reportDate);
      const monday = new Date(date.setDate(date.getDate() - ((date.getDay() + 6) % 7))); // Calculate the Monday of the current week
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6); // Calculate the Sunday of the current week
      const weekKey = `${(monday.getMonth() + 1).toString().padStart(2, '0')}/${monday.getDate().toString().padStart(2, '0')}/${monday.getFullYear()} - ${(sunday.getMonth() + 1).toString().padStart(2, '0')}/${sunday.getDate().toString().padStart(2, '0')}/${sunday.getFullYear()}`;
      if (!weeks[weekKey]) {
        weeks[weekKey] = true;
      }
    });

    // Add the current week if not present
    const today = new Date();
    const currentMonday = new Date(today.setDate(today.getDate() - ((today.getDay() + 6) % 7)));
    const currentSunday = new Date(currentMonday);
    currentSunday.setDate(currentMonday.getDate() + 6);
    const currentWeekKey = `${(currentMonday.getMonth() + 1).toString().padStart(2, '0')}/${currentMonday.getDate().toString().padStart(2, '0')}/${currentMonday.getFullYear()} - ${(currentSunday.getMonth() + 1).toString().padStart(2, '0')}/${currentSunday.getDate().toString().padStart(2, '0')}/${currentSunday.getFullYear()}`;

    if (!weeks[currentWeekKey]) {
      weeks[currentWeekKey] = true;
    }

    return Object.keys(weeks).sort((a, b) => new Date(b.split(' - ')[0]) - new Date(a.split(' - ')[0]));
  };

  const handleWeekChange = (event) => {
    const newSelectedWeekIndex = weeks.indexOf(event.target.value);
    setSelectedWeekIndex(newSelectedWeekIndex);
    filterDailyActivity(newSelectedWeekIndex);
  };

  const handlePreviousWeek = () => {
    if (selectedWeekIndex < weeks.length - 1) {
      const newIndex = selectedWeekIndex + 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const handleNextWeek = () => {
    if (selectedWeekIndex > 0) {
      const newIndex = selectedWeekIndex - 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const filterDailyActivity = (weekIndex, data = originalDailyActivity) => {
    const [mondayStr, sundayStr] = weeks[weekIndex].split(' - ');
    const monday = new Date(mondayStr);
    const sunday = new Date(sundayStr);

    const filteredActivity = data.filter(activity => {
        const reportDate = new Date(activity.reportDate);
        return reportDate >= monday && reportDate <= sunday;
    });

    console.log('Filtered Activity:', filteredActivity.map(activity => activity.reportDate)); // Log filtered activity dates

    setDailyActivity(filteredActivity);
};

const handleCellClick = async (agentData, dayIndex, columnIndex) => {
    const loggedInAgentName = localStorage.getItem('agnName');

    // Allow ROMEROGONZALEZ ARTURO to edit any agent, regardless of hierarchy
    if (loggedInAgentName !== 'ROMEROGONZALEZ ARTURO' && !allowedAgentNumbers.includes(agentData.lagnname)) {
        alert('This agent is outside of your hierarchy.');
        return;
    }

    if (editingCell && !isSaving) {
        console.log('handleCellClick: Saving previous cell before editing new cell');
        await handleSave(formData, 'handleCellClick');
    }

    const activity = getDailyActivityForAgent(agentData.lagnname, dayIndex + 1);

    setEditingCell({
        lagnname: agentData.lagnname,
        dayIndex,
        columnIndex,
        esid: agentData.esid,
        sa: agentData.sa,
        ga: agentData.ga,
        mga: agentData.mga
    });

    const newFormData = {
        appts: activity.appts && activity.appts !== 'N/A' ? activity.appts : '0',
        sits: activity.sits && activity.sits !== 'N/A' ? activity.sits : '0',
        sales: activity.sales && activity.sales !== 'N/A' ? activity.sales : '0',
        alp: activity.alp && activity.alp !== 'N/A' ? activity.alp : '0',
        refs: activity.refs && activity.refs !== 'N/A' ? activity.refs : '0',
        refSits: activity.refSits && activity.refSits !== 'N/A' ? activity.refSits : '0',
        refSales: activity.refSales && activity.refSales !== 'N/A' ? activity.refSales : '0',
        refAlp: activity.refAlp && activity.refAlp !== 'N/A' ? activity.refAlp : '0',
        refAppts: activity.refAppts && activity.refAppts !== 'N/A' ? activity.refAppts : '0',
    };

    console.log('New Form Data for Editing Cell:', newFormData);

    setFormData(newFormData);
    previousCell.current = { lagnname: agentData.lagnname, dayIndex, columnIndex };
    editingRowRef.current = document.querySelector(`#row-${agentData.lagnname}`);
};

  
const handleInputChange = (event) => {
    const { name, value } = event.target;
    const numericValue = (name === 'alp' || name === 'refAlp') ? parseFloat(value) : parseInt(value, 10);

    console.log(`Input changed - Name: ${name}, Value: ${value}, Numeric Value: ${numericValue}`);

    setFormData((prevFormData) => {
        const updatedFormData = {
            ...prevFormData,
            [name]: isNaN(numericValue) ? '' : numericValue,
        };
        console.log('Updated Form Data:', updatedFormData);
        return updatedFormData;
    });
};



const handleSave = async (updatedFormData = formData, source = 'unknown') => {
    if (isSaving) return;
    console.log(`handleSave called from ${source}`);

    try {
        const userId = localStorage.getItem('userId');
        const [mondayStr] = weeks[selectedWeekIndex].split(' - ');
        const monday = new Date(mondayStr);
        const reportDate = new Date(monday);
        reportDate.setDate(reportDate.getDate() + editingCell.dayIndex);
        const formattedReportDate = reportDate.toISOString().split('T')[0];

        const newActivity = {
            userId,
            mga: null,
            agent: editingCell.lagnname,
            reportDate: formattedReportDate,
            rga: null,
            legacy: null,
            tree: null,
            sa: null,
            ga: null,
            agtnum: null,
            clname: null,
            appts: updatedFormData.appts || 0,
            sits: updatedFormData.sits || 0,
            sales: updatedFormData.sales || 0,
            alp: updatedFormData.alp || 0,
            refs: updatedFormData.refs || 0,
            refSits: updatedFormData.refSits || 0,
            refSales: updatedFormData.refSales || 0,
            refAlp: updatedFormData.refAlp || 0,
            refAppts: updatedFormData.refAppts || 0
        };

        console.log('Prepared New Activity for Submission:', newActivity);

        const response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/submitDailyActivity', newActivity);
        console.log('Response:', response.data);
        if (response.data.success) {
            console.log('Creating new entry');
        } else {
            console.error('Error response from server:', response.data.message);
        }

        const responseDailyActivity = await axios.get('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getAllDailyActivity');
        if (responseDailyActivity.data.success) {
            const activityData = responseDailyActivity.data.data.map(activity => ({
                ...activity,
                reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
            }));
            setOriginalDailyActivity(activityData);
            filterDailyActivity(selectedWeekIndex, activityData);
            console.log('Daily activity data updated');
        } else {
            setError('Error fetching updated daily activity data');
        }

        setEditingCell(null);
    } catch (error) {
        console.error('Error saving data:', error);
    } finally {
        setIsSaving(false);
    }
};


  
  const scrollToCurrentDay = () => {
    const today = new Date();
    const dayIndex = today.getDay() - 1; // getDay() returns 0 for Sunday, 1 for Monday, etc.
    if (dayIndex === 0) return; // Do not scroll if today is Sunday or Monday
  
    if (tableContainerRef.current) {
      const yesterdayDayIndex = dayIndex - 1;
      const yesterdayHeader = tableContainerRef.current.querySelector(`th[data-day-index="${yesterdayDayIndex}"]`);
      if (yesterdayHeader) {
        const additionalOffset = 171; // Adjust this value as needed
        const offset = yesterdayHeader.offsetLeft + yesterdayHeader.offsetWidth - additionalOffset;
        tableContainerRef.current.scrollTo({ left: offset, behavior: 'instant' });
      }
    }
  };
  
  
  
  const handleCancel = () => {
    setEditingCell(null);
    setFormData({});
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const formatCurrency = (number) => {
    return `$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)}`;
};

const getDailyActivityForAgent = (lagnname, day) => {
    const activity = dailyActivity.find(activity => {
        const reportDate = new Date(activity.reportDate);
        return activity.agent === lagnname && reportDate.getDay() === day;
    });
    return activity || {
        appts: 'N/A',
        sits: 'N/A',
        sales: 'N/A',
        alp: 'N/A',
        refs: 'N/A',
        refSits: 'N/A',
        refSales: 'N/A',
        refAlp: 'N/A',
        refAppts: 'N/A',
    };
};

  const groupedData = data.reduce((acc, item) => {
    const rgaKey = item.RGA || 'No RGA';
    if (!acc[rgaKey]) {
      acc[rgaKey] = [];
    }
    acc[rgaKey].push(item);
    return acc;
  }, {});

  data.forEach((item) => {
    const mgaRGA = item.MGA;
    if (groupedData[mgaRGA]) {
      groupedData[mgaRGA].push(item);
    }
  });

  const renderTabs = () => {
    const sortedRGAs = Object.keys(groupedData).sort((a, b) => {
      if (a === 'No RGA') return 1; // Place 'No RGA' at the end
      if (b === 'No RGA') return -1;
      return a.localeCompare(b);
    });
  
    return sortedRGAs.map((rga) => {
      if (rga !== 'No RGA') {
        const abbreviatedName = rga.split(' ')[0];
        return (
          <Tab eventKey={rga} title={abbreviatedName} key={rga}>
            <MGAWithAGTs
              mgas={groupedData[rga]}
              dailyActivity={dailyActivity}
              selectedWeek={weeks[selectedWeekIndex]}
              editingCell={editingCell}
              formData={formData}
              handleCellClick={handleCellClick}
              handleInputChange={handleInputChange}
              handleSave={handleSave}
              handleCancel={handleCancel}
              inputRefs={inputRefs} // Pass the inputRefs to the child component
              editingRowRef={editingRowRef} // Pass the editingRowRef to the child component
              tableContainerRef={tableContainerRef} // Pass the tableContainerRef to the child component
            />
          </Tab>
        );
      }
      return null;
    });
  };
  
  

  return (
    <div>
        {loading ? (
            <Placeholder />
        ) : (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                    <button className='hier-change-week-left' onClick={handlePreviousWeek} disabled={selectedWeekIndex === weeks.length - 1}>{'<'}</button>
                    <select onChange={handleWeekChange} value={weeks[selectedWeekIndex]}>
                        {weeks.map(week => (
                            <option value={week} key={week}>{week}</option>
                        ))}
                    </select>
                    <button className='hier-change-week-right' onClick={handleNextWeek} disabled={selectedWeekIndex === 0}>{'>'}</button>
                </div>
                          
                <Tabs defaultActiveKey={Object.keys(groupedData).find(rga => rga !== 'No RGA')} id="rga-tabs">
                    {renderTabs()}
                </Tabs>
            </>
        )}
    </div>
);

};

const reformatAgentName = (name) => {
  if (!name) return ''; // Check if name is undefined or null
  const parts = name.split(' ');
  if (parts.length < 2) return name;
  const [last, first, ...rest] = parts;
  return `${first} ${rest.join(' ')} ${last}`;
};

const MGAWithAGTs = ({ mgas, dailyActivity, selectedWeek, editingCell, formData, handleCellClick, handleInputChange, handleSave, handleCancel, inputRefs, editingRowRef, tableContainerRef }) => {
    const [mondayStr, sundayStr] = selectedWeek.split(' - ');
    const monday = new Date(mondayStr);
    const sunday = new Date(sundayStr);
  
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
    };
    
    const formatCurrency = (number) => {
        return `$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)}`;
    };
    const getDailyActivityForAgent = (lagnname, day) => {
      const activity = dailyActivity.find(activity => {
        const reportDate = new Date(activity.reportDate);
        return activity.agent === lagnname && reportDate.getDay() === day;
      });
      return activity || {
        appts: 'N/A',
        sits: 'N/A',
        sales: 'N/A',
        alp: 'N/A',
        refs: 'N/A',
        refSits: 'N/A',
        refSales: 'N/A',
        refAlp: 'N/A',
        refAppts: 'N/A',
      };
    };
  
    const calculateTotalsForAgent = (lagnname) => {
        return dailyActivity.reduce((acc, activity) => {
            if (activity.agent === lagnname) {
                acc.appts += parseInt(activity.appts, 10) || 0;
                acc.sits += parseInt(activity.sits, 10) || 0;
                acc.sales += parseInt(activity.sales, 10) || 0;
                acc.alp += parseInt(activity.alp, 10) || 0;
                acc.refs += parseInt(activity.refs, 10) || 0;
                acc.refSits += parseInt(activity.refSits, 10) || 0;
                acc.refSales += parseInt(activity.refSales, 10) || 0;
                acc.refAlp += parseFloat(activity.refAlp) || 0;
                acc.refAppts += parseInt(activity.refAppts, 10) || 0;
            }
            return acc;
        }, {
            appts: 0,
            sits: 0,
            sales: 0,
            alp: 0,
            refs: 0,
            refSits: 0,
            refSales: 0,
            refAlp: 0,
            refAppts: 0,
        });
    };
  
    const calculateDailyTotals = (agents) => {
      const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      const dailyTotals = daysOfWeek.map(() => ({
        appts: 0,
        sits: 0,
        sales: 0,
        alp: 0,
        refs: 0,
        refSits: 0,
        refSales: 0,
        refAlp: 0,
        refAppts: 0,
      }));
  
      const accumulateAgentData = (agent) => {
        daysOfWeek.forEach((_, dayIndex) => {
          const activity = getDailyActivityForAgent(agent.lagnname, dayIndex + 1);
          Object.keys(dailyTotals[dayIndex]).forEach(key => {
            const value = parseInt(activity[key], 10) || 0;
            dailyTotals[dayIndex][key] += value;
          });
        });
  
        if (agent.children && agent.children.length > 0) {
          agent.children.forEach(child => accumulateAgentData(child));
        }
      };
  
      agents.forEach(agent => {
        accumulateAgentData(agent);
      });
  
      return dailyTotals;
    };
  
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  
    const buildHierarchy = (data) => {
        const hierarchy = [];
        const map = {};
        const inactiveNodes = [];
      
        // Initialize map with each item
        data.forEach(item => {
          map[item.lagnname] = { ...item, children: [] };
        });
      
        // Group children under their respective parents based on the hierarchy rules
        data.forEach(item => {
          if (item.managerActive === 'n') {
            return; // Skip inactive managers
          }
      
          if (item.sa && map[item.sa] && map[item.sa].managerActive === 'y') {
            map[item.sa].children.push(map[item.lagnname]);
          } else if (item.ga && map[item.ga] && map[item.ga].managerActive === 'y') {
            map[item.ga].children.push(map[item.lagnname]);
          } else if (item.mga && map[item.mga]) {
            map[item.mga].children.push(map[item.lagnname]);
          } else if (item.rga && map[item.rga]) {
            map[item.rga].children.push(map[item.lagnname]);
          } else {
            hierarchy.push(map[item.lagnname]);
          }
        });
      
        // Ensure the hierarchy follows the specified order
        const sortHierarchy = (nodes) => {
          return nodes.sort((a, b) => {
            const order = ['RGA', 'MGA', 'GA', 'AGT', 'SA'];
            const orderA = order.indexOf(a.clname);
            const orderB = order.indexOf(b.clname);
      
            // Custom order for AGT with null sa and non-null ga before SA with null sa and non-null ga
            if (orderA === orderB) {
              if (a.clname === 'AGT' && b.clname === 'SA') {
                if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                  return -1;
                }
              } else if (a.clname === 'SA' && b.clname === 'AGT') {
                if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                  return 1;
                }
              }
              return a.managerActive === 'n' ? 1 : -1;
            }
      
            return orderA - orderB;
          }).map(node => {
            node.children = sortHierarchy(node.children);
            return node;
          });
        };
      
        const sortedHierarchy = sortHierarchy(hierarchy);
      
        return { sortedHierarchy };
      };
      
  
    const getColorForClname = (clname) => {
      switch (clname) {
        case 'RGA':
          return 'lightblue';
        case 'MGA':
          return '';
        case 'GA':
          return '#ED722F';
        case 'AGT':
          return 'black';
        case 'SA':
          return '#B25271';
        default:
          return 'black';
      }
    };
  
    const getFontWeightForClname = (clname) => {
      switch (clname) {
        case 'MGA':
        case 'GA':
        case 'SA':
          return 'bold';
        default:
          return 'normal';
      }
    };
  
    const loggedInAgentName = localStorage.getItem('agnName');
  
    const renderAgents = (agent) => {
        if (agent.managerActive === 'n' || agent.Active === 'n') {
            return null; // Skip rendering this agent
        }
    
        const agentTotals = calculateTotalsForAgent(agent.lagnname);
        const clnameColor = getColorForClname(agent.clname);
        const clnameFontWeight = getFontWeightForClname(agent.clname);
        const isLoggedInAgent = agent.lagnname === loggedInAgentName;
    
        return (
            <React.Fragment key={agent.lagnname}>
                <tr
                    id={`row-${agent.lagnname}`}
                    ref={editingCell && editingCell.lagnname === agent.lagnname ? editingRowRef : null}
                    className={editingCell && editingCell.lagnname === agent.lagnname ? 'editing-row' : ''}
                >
                    <td className="first-column" style={{ backgroundColor: isLoggedInAgent ? '#ED722F' : 'black' }}>
                        {reformatAgentName(agent.lagnname)}
                    </td>
                    <td style={{ color: clnameColor, fontWeight: clnameFontWeight }}>{agent.additionalDetails}</td>
                    {daysOfWeek.map((_, dayIndex) => {
                        const activity = getDailyActivityForAgent(agent.lagnname, dayIndex + 1);
                        const isEditing = editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex;
                        const inputKey = `${agent.lagnname}-${dayIndex}-`;
    
                        return (
                            <React.Fragment key={`${dayIndex}-${agent.lagnname}`}>
                                {isEditing ? (
                                    <>
                                        <td className="editing-cell"><input type="text" name="appts" value={formData.appts} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 1} ref={(el) => inputRefs.current[`${inputKey}1`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="sits" value={formData.sits} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 2} ref={(el) => inputRefs.current[`${inputKey}2`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="sales" value={formData.sales} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 3} ref={(el) => inputRefs.current[`${inputKey}3`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="alp" value={formData.alp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 4} ref={(el) => inputRefs.current[`${inputKey}4`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="refs" value={formData.refs} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 5} ref={(el) => inputRefs.current[`${inputKey}5`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="refSits" value={formData.refSits} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 6} ref={(el) => inputRefs.current[`${inputKey}6`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="refSales" value={formData.refSales} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 7} ref={(el) => inputRefs.current[`${inputKey}7`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="refAlp" value={formData.refAlp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 8} ref={(el) => inputRefs.current[`${inputKey}8`] = el} /></td>
                                        <td className="editing-cell"><input type="text" name="refAppts" value={formData.refAppts} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 9} ref={(el) => inputRefs.current[`${inputKey}9`] = el} /></td>
                                    </>
                                ) : (
                                    <>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 1)}>{activity.appts !== 'N/A' ? formatNumber(activity.appts) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 2)}>{activity.sits !== 'N/A' ? formatNumber(activity.sits) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 3)}>{activity.sales !== 'N/A' ? formatNumber(activity.sales) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 4)}>{activity.alp !== 'N/A' ? formatCurrency(activity.alp) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 5)}>{activity.refs !== 'N/A' ? formatNumber(activity.refs) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 6)}>{activity.refSits !== 'N/A' ? formatNumber(activity.refSits) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 7)}>{activity.refSales !== 'N/A' ? formatNumber(activity.refSales) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 8)}>{activity.refAlp !== 'N/A' ? formatCurrency(activity.refAlp) : 'N/A'}</td>
                                        <td onClick={() => handleCellClick(agent, dayIndex, 9)}>{activity.refAppts !== 'N/A' ? formatNumber(activity.refAppts) : 'N/A'}</td>
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}
    
                    <td>{formatNumber(agentTotals.appts)}</td>
                    <td>{formatNumber(agentTotals.sits)}</td>
                    <td>{formatNumber(agentTotals.sales)}</td>
                    <td>{formatCurrency(agentTotals.alp)}</td>
                    <td>{formatNumber(agentTotals.refs)}</td>
                    <td>{formatNumber(agentTotals.refSits)}</td>
                    <td>{formatNumber(agentTotals.refSales)}</td>
                    <td>{formatCurrency(agentTotals.refAlp)}</td>
                    <td>{formatNumber(agentTotals.refAppts)}</td>
                </tr>
                {agent.children && agent.children.map(subordinate => renderAgents(subordinate))}
            </React.Fragment>
        );
    };
  
    const renderTotalsRow = (dailyTotals) => {
        return (
            <tr className="totals-row">
                <td>Totals</td>
                <td></td>
                {daysOfWeek.map((_, dayIndex) => (
                    <React.Fragment key={`totals-${dayIndex}`}>
                        <td>{formatNumber(dailyTotals[dayIndex].appts)}</td>
                        <td>{formatNumber(dailyTotals[dayIndex].sits)}</td>
                        <td>{formatNumber(dailyTotals[dayIndex].sales)}</td>
                        <td>{formatCurrency(dailyTotals[dayIndex].alp)}</td>
                        <td>{formatNumber(dailyTotals[dayIndex].refs)}</td>
                        <td>{formatNumber(dailyTotals[dayIndex].refSits)}</td>
                        <td>{formatNumber(dailyTotals[dayIndex].refSales)}</td>
                        <td>{formatCurrency(dailyTotals[dayIndex].refAlp)}</td>
                        <td>{formatNumber(dailyTotals[dayIndex].refAppts)}</td>
                    </React.Fragment>
                ))}
                <td>{formatNumber(dailyTotals.reduce((acc, day) => acc + day.appts, 0))}</td>
                <td>{formatNumber(dailyTotals.reduce((acc, day) => acc + day.sits, 0))}</td>
                <td>{formatNumber(dailyTotals.reduce((acc, day) => acc + day.sales, 0))}</td>
                <td>{formatCurrency(dailyTotals.reduce((acc, day) => acc + day.alp, 0))}</td>
                <td>{formatNumber(dailyTotals.reduce((acc, day) => acc + day.refs, 0))}</td>
                <td>{formatNumber(dailyTotals.reduce((acc, day) => acc + day.refSits, 0))}</td>
                <td>{formatNumber(dailyTotals.reduce((acc, day) => acc + day.refSales, 0))}</td>
                <td>{formatCurrency(dailyTotals.reduce((acc, day) => acc + day.refAlp, 0))}</td>
                <td>{formatNumber(dailyTotals.reduce((acc, day) => acc + day.refAppts, 0))}</td>
            </tr>
        );
    };
  
    return (
      <div>
{mgas.map((mga) => {
  const parts = mga.AGTs.split('; ');
  const agents = parts.map((part, index) => {
    if (index === 0) {
      const [lagnname, managerActive, esid, clname] = part.split(' - ');
      return { lagnname, managerActive, esid, clname: 'MGA', additionalDetails: 'MGA' };
    } else {
      return part.split('-- ').map(agt => {
        const [lagnname, managerActive, esid, sa, ga, mga, clname] = agt.split(' - ');
        return { lagnname, managerActive, esid, sa, ga, mga, clname, additionalDetails: clname };
      });
    }
  }).flat();
  
          const { sortedHierarchy } = buildHierarchy(agents);
          const dailyTotals = calculateDailyTotals(sortedHierarchy);
  
          return (
            <div className="hier-table-wrapper" key={mga.MGA}>
              <table className="hierarchyTable">
                <thead>
                  <tr className="first-row">
                    <th className="day-header">
                      <h5 className="hier-mga-header">
                        {mga.MGA.split(' ')[0]}
                      </h5>
                    </th>
                    <th className="day-header">MGA</th>
                    {daysOfWeek.map((day, index) => (
                      <th colSpan="9" className="day-header" key={day} data-day-index={index}>
                        {day} - {(new Date(monday.getTime() + index * 86400000)).toLocaleDateString()}
                      </th>
                    ))}
                    <th colSpan="10" className="day-header">Totals</th>
                  </tr>
                  <tr className="second-row">
                    <th style={{ backgroundColor: 'transparent' }}></th>
                    <th style={{ backgroundColor: 'transparent' }}></th>
                    {daysOfWeek.map(day => (
                      <React.Fragment key={day}>
                        <th style={{ backgroundColor: '' }} className="header-appts">Appts</th>
                        <th style={{ backgroundColor: '' }} className="header-sits">Sits</th>
                        <th style={{ backgroundColor: '' }} className="header-sales">Sales</th>
                        <th style={{ backgroundColor: '' }} className="header-alp">ALP</th>
                        <th style={{ backgroundColor: '' }} className="header-refs">Refs</th>
                        <th style={{ backgroundColor: '' }} className="header-refSits">Ref Sits</th>
                        <th style={{ backgroundColor: '' }} className="header-refSales">Ref Sales</th>
                        <th style={{ backgroundColor: '' }} className="header-refAlp">Ref ALP</th>
                        <th style={{ backgroundColor: '' }} className="header-refAppts">Proj Appts</th>
                      </React.Fragment>
                    ))}
                    <th className="header-appts">Appts</th>
                    <th className="header-sits">Sits</th>
                    <th className="header-sales">Sales</th>
                    <th className="header-alp">ALP</th>
                    <th className="header-refs">Refs</th>
                    <th className="header-refSits">Ref Sits</th>
                    <th className="header-refSales">Ref Sales</th>
                    <th className="header-refAlp">Ref ALP</th>
                    <th className="header-refAppts">Proj Appts</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedHierarchy.map(agent => renderAgents(agent))}
                  {renderTotalsRow(dailyTotals)}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

export default ActiveUsersTable;
