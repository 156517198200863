import React from 'react';
import './GridContent.css';

const TrainingMaterials = () => {
  return (
    <div className="grid-content" id="trainingMaterials">
      <div className="grid-cat">
        <div className="category">Training Materials</div>
        <div className="category-link"><a href="https://drive.google.com/drive/folders/1Vlk0zQF3OFE4YVcyvJQpTVTf4EO-gva7?usp=sharing" target="_blank" rel="noopener noreferrer">New Agent Training <img src="/img/Google_Drive_icon_2020.svg.png" alt="Google Drive" style={{ verticalAlign: 'baseline' }} /></a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/training_docs/Training_Outline.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Training Outline & Release Checklist</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Plan_Descriptions.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Plan Descriptions</a></div>
        <div className="category-link"><a href="https://legacyail.com/agent_tools/common_documents/Product Knowledge.pdf#view=Fit" target="_blank" rel="noopener noreferrer">Product Knowledge</a></div>
        <div className="category-link"><a href="https://impactaws.ailife.com/#/download" target="_blank" rel="noopener noreferrer">Download eApp</a></div>
      </div>
    </div>
  );
};

export default TrainingMaterials;
