import React, { useEffect, useState } from 'react';
import MyTrophyCase from '../Trophy/MyTrophyCase';
import HierarchyTable from '../Management/HierarchyTable';
import AccountCreationForm from './CreateAccount';
import { Modal } from 'react-bootstrap';

const Account = () => {
    const [userData, setUserData] = useState(null);
    const [profilePicture, setProfilePicture] = useState('');
    const [tenure, setTenure] = useState('');
    const [anniversaryMessage, setAnniversaryMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Fetch user data from local storage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            setUserData(userInfo);
            setProfilePicture(userInfo.profpic || 'https://via.placeholder.com/150');
            calculateTenure(userInfo.esid);
        }
    }, []);

    const calculateTenure = (esid) => {
        const startDate = new Date(esid);
        const now = new Date();

        // Reset time components to 0 for comparison
        startDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        const diffTime = now - startDate;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const years = Math.floor(diffDays / 365);
        const months = Math.floor((diffDays % 365) / 30);
        const weeks = Math.floor((diffDays % 30) / 7);
        const days = diffDays % 7;

        let tenureString = '';

        if (years > 0) {
            tenureString += `${years} year${years > 1 ? 's' : ''}`;
        }

        if (months > 0) {
            if (tenureString) {
                tenureString += `, `;
            }
            tenureString += `${months} month${months > 1 ? 's' : ''}`;
        }

        if (weeks > 0) {
            if (tenureString) {
                tenureString += `, `;
            }
            tenureString += `${weeks} week${weeks > 1 ? 's' : ''}`;
        }

        if (days > 0) {
            if (tenureString) {
                tenureString += `, `;
            }
            tenureString += `${days} day${days > 1 ? 's' : ''}`;
        }

        setTenure(tenureString);

        // Check for anniversary
        const startMonthDay = startDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
        const nowMonthDay = now.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });

        if (startMonthDay === nowMonthDay) {
            setAnniversaryMessage(`Happy ${years} year${years > 1 ? 's' : ''} Anniversary!`);
        } else {
            setAnniversaryMessage('');
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('profilePic', file);
            formData.append('userId', userData.id);

            try {
                const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/upload-profile-pic', {
                    method: 'POST',
                    body: formData,
                });
                const result = await response.json();
                if (result.success) {
                    setProfilePicture(result.filePath);
                    // Update userInfo in localStorage
                    const updatedUserInfo = { ...userData, profpic: result.filePath };
                    setUserData(updatedUserInfo); // Update state
                    localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleRemovePicture = async () => {
        try {
            const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/remove-profile-picture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userData.id }),
            });
            const result = await response.json();
            if (result.success) {
                setProfilePicture('https://via.placeholder.com/150');
                // Update userInfo in localStorage
                const updatedUserInfo = { ...userData, profpic: '' };
                setUserData(updatedUserInfo); // Update state
                localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
            }
        } catch (error) {
            console.error('Error removing profile picture:', error);
        }
    };

    if (!userData) {
        return <div>Loading...</div>;
    }
    const userRole = localStorage.getItem('userRole');
    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);
    return (
        <div className='container'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
                <div className="image-container">
                    <img
                        src={profilePicture}
                        alt="Profile"
                        style={{
                            objectFit: 'cover',
                            width: '150px',
                            height: '150px',
                            borderRadius: '50%',
                        }}
                    />
                </div>
                <div>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            document.getElementById('fileInput').click();
                        }}
                        style={{ marginRight: '20px', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        Upload
                    </a>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleRemovePicture();
                        }}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        Remove
                    </a>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <h2>{userData.screen_name}</h2>
                    {anniversaryMessage && <p style={{ color: 'green', fontWeight: 'bold' }}>{anniversaryMessage}</p>}
                    <p><strong>Tenure: {tenure}</strong></p>
                    <p>{userData.email}</p>
                    <p>{userData.phone}</p>
                </div>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleFileChange}
                />
                <div style={{marginBottom: '20px'}}>
<div style={{marginBottom: '25px'}}>
 {['GA', 'MGA', 'RGA'].includes(userRole) && (
        <button onClick={handleOpenModal} className="dashboard-button">
          Create Trainee Account
        </button>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
        <AccountCreationForm />
        </Modal.Body>
      </Modal> </div>
            <HierarchyTable />
            </div>
            </div>
        </div>
    );
};

export default Account;
