import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import ReportActivityForm from '../ReportActivityForm';
import './Dashboard.css';
import { trackEvent } from '../utils/analytics';
import CombinedRank from './CombinedRank';
import CustomNavbar from '../Navbar'; // Import the CustomNavbar component
import HierarchyTable from '../Production/HierarchyDisplay';

const Dashboard = () => {
    useEffect(() => {
        document.title = 'Power Rankings';
    }, []);
    const calculateDateRange = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const dayOfMonth = today.getDate();
        const currentDay = today.getDay();
        const daysToLastMonday = currentDay === 0 ? 6 : dayOfWeek - 1;
        const mostRecentMonday = new Date(today);
        mostRecentMonday.setDate(dayOfMonth - daysToLastMonday);
        mostRecentMonday.setHours(0, 0, 0, 0);
        const nextSunday = new Date(mostRecentMonday);
        nextSunday.setDate(mostRecentMonday.getDate() + 6);
        nextSunday.setHours(23, 59, 59, 999);

        return [
            {
                startDate: mostRecentMonday,
                endDate: nextSunday,
                key: 'selection',
            },
        ];
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reportFormData, setReportFormData] = useState(() => {
        const savedData = localStorage.getItem('reportFormData');
        return savedData ? JSON.parse(savedData) : {};
    });

    const [filteredData, setFilteredData] = useState([]);
    const [dateRange, setDateRange] = useState(calculateDateRange());
    const [currentSection, setCurrentSection] = useState('leaderboards');

    const handleCloseModal = () => setIsModalOpen(false);

    useEffect(() => {
        localStorage.setItem('reportFormData', JSON.stringify(reportFormData));
    }, [reportFormData]);

    const handleOpenModal = () => {
        const savedData = localStorage.getItem('reportFormData');
        if (savedData) {
            setReportFormData(JSON.parse(savedData));
        }
        setIsModalOpen(true);
    };

    const handlePresentationLauncherClick = () => {
        trackEvent("presentation_launcher_click", {
            category: "Engagement",
            action: "Click",
            label: "Presentation Launcher"
        });
    };
    const popupWindows = React.useRef([]); // Use ref to persist popup windows across renders



    // Function to open presentations
    const openPres = (presFile) => {
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
        const width = screenWidth > 3800 ? Math.round(screenWidth * 0.35) : Math.round(screenWidth * 0.7);
        const height = Math.round(screenHeight * 0.7);
        const left = screenWidth > 3800 ? Math.round((screenWidth - width) / 2.61) : 0;
        const top = screenHeight - height;

        // If a previously opened script is still open, do not open a new one
        let openedWindow = null;
        for (const win of popupWindows.current) {
            if (!win.closed) {
                openedWindow = win;
                break;
            }
        }

        if (openedWindow) {
            openedWindow.location.href = presFile;
            openedWindow.focus();
            openedWindow.moveToTop();
        } else {
            const presWindow = window.open(
                presFile,
                '_blank',
                `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
            );
            popupWindows.current.push(presWindow);
            presWindow.focus();
        }
    };

    const openNormalTab = (url) => {
        window.open(url, '_blank');
    };


    return (
        <div className="app-container">

            <CombinedRank data={filteredData} dateRange={dateRange} />

            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <ReportActivityForm formData={reportFormData} setFormData={setReportFormData} closeForm={handleCloseModal} />
            </Modal>
        </div>
    );
};

export default Dashboard;
