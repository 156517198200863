import React, { useState, useEffect } from 'react';
import ActiveUsersTable from '../ActiveUsers/ActiveUsersTable';
import LoggedInAgentForm from '../ActiveUsers/LoggedInAgentForm';
import 'bootstrap/dist/css/bootstrap.min.css';

const Production = () => {
    const [view, setView] = useState('myview');
    const [selectedAgent, setSelectedAgent] = useState(''); // State to store selected agent

    return (
        <div className='app-container'>
            <div className='large-app-container'>
                <div>
                    <div className="tabs">
                        <input
                            type="radio"
                            id="myview"
                            name="view_type"
                            value="myview"
                            checked={view === 'myview'}
                            onChange={() => setView('myview')}
                        />
                        <label htmlFor="myview">My View</label>
                        <input
                            type="radio"
                            id="team"
                            name="view_type"
                            value="team"
                            checked={view === 'team'}
                            onChange={() => setView('team')}
                        />
                        <label htmlFor="team">Team View</label>
                    </div>

                    {view === 'myview' && <LoggedInAgentForm onAgentChange={setSelectedAgent} />}
                    {view === 'team' && <ActiveUsersTable />}
                </div>
            </div>
        </div>
    );
};

export default Production;
