import React from 'react';
import HierarchyTable from './HierarchyTable';

const ParentComponent = () => {
    // Your component logic here

    return (
        <div>
            <h1>Parent Component</h1>
            <HierarchyTable />
        </div>
    );
};

export default ParentComponent;