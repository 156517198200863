import React, { useState, useEffect } from 'react';
import '../../Leaderboards.css';
import { TailSpin } from 'react-loader-spinner';

const formatCurrentWeeklyDateRange = (dateString) => {
    const date = new Date(dateString);
    const startOfWeek = new Date(date);
    const endOfWeek = new Date(date);

    // Set to start of the week (Monday)
    startOfWeek.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
    startOfWeek.setHours(0, 0, 0, 0);

    // Set to end of the week (Sunday)
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedStartOfWeek = startOfWeek.toLocaleDateString('en-US', options);
    const formattedEndOfWeek = endOfWeek.toLocaleDateString('en-US', options);

    return `Week of ${formattedStartOfWeek} - ${formattedEndOfWeek}`;
};

const formatReportedMTDDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace('/', '-'); // Extract only MM/YYYY
};

const formatReportedYTDDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear().toString();
};

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // No decimal places
        maximumFractionDigits: 0  // No decimal places
    }).format(value);
};

const reformatMGANameWithMGA = (name) => {
    if (!name) return '';

    // List of common suffixes
    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];

    const parts = name.split(' ');
    if (parts.length < 2) return name;

    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

    // Assuming that a middle initial will be a single character
    let [last, first, middle, ...rest] = parts.length === 3 && parts[2].length === 1 ? parts : [parts[0], parts[1], ...parts.slice(2)];

    // Check for and remove suffix if present
    if (suffixes.includes(rest[rest.length - 1])) {
        rest.pop();
    }

    const abbreviatedLast = `${capitalize(last.charAt(0))}.`;

    const formattedName = rest.length > 0 
        ? `${capitalize(first)} ${rest.map(capitalize).join(' ')} ${abbreviatedLast}` 
        : `${capitalize(first)} ${abbreviatedLast}`;

    // Determine the MGA value as the last name in all caps
    let displayMGA = last.toUpperCase();

    return (
        <>
            <div>{formattedName}</div>
            <div className="mga-value">{displayMGA}</div>
        </>
    );
};

const parseWeekString = (weekString) => {
    const parts = weekString.split(' ');
    const startOfWeek = new Date(parts[2]);
    return startOfWeek;
};

const MGAReportedRank = ({ selectedPeriodTab, selectedDate, activeUsersData, dailyActivityData }) => { // Accept dailyActivityData as a prop
    const [mgaData, setMgaData] = useState([]);
    const [processedData, setProcessedData] = useState({ weekly: {}, monthly: {}, yearly: {} });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMGAData = async () => {
            try {
                const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/mgaHierarchy');
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    const sixMonthsAgo = new Date();
                    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

                    const filteredData = data.data.map(mgaItem => {
                        const filteredAGTs = mgaItem.AGTs.split('-- ').filter(agent => {
                            const esid = agent.split(' - ')[2];
                            const esidDate = new Date(esid.split(' ')[0]);
                            return esidDate >= sixMonthsAgo;
                        }).join(';');

                        return { ...mgaItem, AGTs: filteredAGTs || 'No valid AGTs' };
                    });
                    setMgaData(filteredData);
                } else {
                    setError('Failed to load MGA data');
                }
            } catch (err) {
                setError('Failed to load MGA data');
            } finally {
                setLoading(false);
            }
        };

        fetchMGAData(); // Fetch only MGA data
    }, []);


    useEffect(() => {
        if (mgaData.length > 0 && dailyActivityData.length > 0) {
            const processed = processReportedData(mgaData, dailyActivityData);
            setProcessedData(processed);
        }
    }, [mgaData, dailyActivityData]);


    const processReportedData = (mgaData, dailyActivityData) => {
        const data = {
            weekly: {},
            monthly: {},
            yearly: {}
        };
    
        const weekKeys = new Set();
        const monthKeys = new Set();
        const yearKeys = new Set();
    
        // Precompute all date keys
        dailyActivityData.forEach(item => {
            weekKeys.add(formatCurrentWeeklyDateRange(item.reportDate.split('T')[0]));
            monthKeys.add(formatReportedMTDDate(item.reportDate.split('T')[0]));
            yearKeys.add(formatReportedYTDDate(item.reportDate.split('T')[0]));
        });
    
        // Initialize all MGAs with 0 values using precomputed keys
        mgaData.forEach(mgaItem => {
            weekKeys.forEach(week => {
                if (!data.weekly[week]) data.weekly[week] = {};
                if (!data.weekly[week][mgaItem.MGA]) data.weekly[week][mgaItem.MGA] = { alp: 0 };
            });
    
            monthKeys.forEach(month => {
                if (!data.monthly[month]) data.monthly[month] = {};
                if (!data.monthly[month][mgaItem.MGA]) data.monthly[month][mgaItem.MGA] = { alp: 0 };
            });
    
            yearKeys.forEach(year => {
                if (!data.yearly[year]) data.yearly[year] = {};
                if (!data.yearly[year][mgaItem.MGA]) data.yearly[year][mgaItem.MGA] = { alp: 0 };
            });
        });
    
        // Populate data based on daily activity
        dailyActivityData.forEach(item => {
            const week = formatCurrentWeeklyDateRange(item.reportDate.split('T')[0]);
            const month = formatReportedMTDDate(item.reportDate.split('T')[0]);
            const year = formatReportedYTDDate(item.reportDate.split('T')[0]);
    
            mgaData.forEach(mgaItem => {
                const agentNames = mgaItem.AGTs.split(';').map(agent => agent.split(' - ')[0].trim());
                if (agentNames.includes(item.agent)) {
                    data.weekly[week][mgaItem.MGA].alp += parseFloat(item.alp || 0);
                    data.monthly[month][mgaItem.MGA].alp += parseFloat(item.alp || 0);
                    data.yearly[year][mgaItem.MGA].alp += parseFloat(item.alp || 0);
                }
            });
        });
    
        // Add MGAs with zero values if not present in daily activity data
        mgaData.forEach(mgaItem => {
            weekKeys.forEach(week => {
                if (!data.weekly[week][mgaItem.MGA]) {
                    data.weekly[week][mgaItem.MGA] = { alp: 0 };
                }
            });
    
            monthKeys.forEach(month => {
                if (!data.monthly[month][mgaItem.MGA]) {
                    data.monthly[month][mgaItem.MGA] = { alp: 0 };
                }
            });
    
            yearKeys.forEach(year => {
                if (!data.yearly[year][mgaItem.MGA]) {
                    data.yearly[year][mgaItem.MGA] = { alp: 0 };
                }
            });
        });
    
        return data;
    };
    

    const getRankedData = (data) => {
        const activeUsersSet = new Set(activeUsersData.map(user => user.lagnname));

        const rankedData = mgaData
            .filter(mgaItem => activeUsersSet.has(mgaItem.MGA))
            .map(mgaItem => {
                const alp = data[mgaItem.MGA]?.alp || 0;
                return { MGA: mgaItem.MGA, alp };
            })
            .sort((a, b) => b.alp - a.alp)
            .map((item, index) => ({ ...item, rank: index + 1 }));

            return rankedData.slice(0, 3); // Limit to top 3 entries
        };

    if (loading) {
        return (
            <div className="leaderboard-card table-overlay">
                <div className="loading-overlay">
                    <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={40}
                        width={40}
                    />
                </div>
                <table className="leaderboard-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>MGA</th>
                            <th>ALP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Empty rows to maintain table structure during loading */}
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const currentData = (() => {
        if (selectedPeriodTab === 'Weekly Recap') {
            const [start, end] = selectedDate.split(' - ');
            const weeklyData = dailyActivityData
                .filter(item => {
                    const date = item.reportDate.split('T')[0];
                    return date >= start && date <= end;
                })
                .reduce((acc, item) => {
                    mgaData.forEach(mgaItem => {
                        const agentNames = mgaItem.AGTs.split(';').map(agent => agent.split(' - ')[0].trim());
                        agentNames.forEach(agent => {
                            if (agent === item.agent) {
                                if (!acc[mgaItem.MGA]) acc[mgaItem.MGA] = { alp: 0 };
                                acc[mgaItem.MGA].alp += parseFloat(item.alp || 0);
                            }
                        });
                    });
                    return acc;
                }, {});

            // Add active users with zero values if not present in weeklyData
            activeUsersData.forEach(user => {
                if (!weeklyData[user.lagnname]) {
                    weeklyData[user.lagnname] = { alp: 0 };
                }
            });

            return weeklyData;
        } else if (selectedPeriodTab === 'MTD Recap') {
            const monthlyData = processedData.monthly[selectedDate] || {};

            // Add active users with zero values if not present in monthlyData
            activeUsersData.forEach(user => {
                if (!monthlyData[user.lagnname]) {
                    monthlyData[user.lagnname] = { alp: 0 };
                }
            });

            return monthlyData;
        } else if (selectedPeriodTab === 'YTD Recap') {
            const yearlyData = processedData.yearly[selectedDate] || {};

            // Add active users with zero values if not present in yearlyData
            activeUsersData.forEach(user => {
                if (!yearlyData[user.lagnname]) {
                    yearlyData[user.lagnname] = { alp: 0 };
                }
            });

            return yearlyData;
        }
    })();

    const rankedDailyActivityData = getRankedData(currentData);

    const renderDailyActivityTableRows = (rankedData) => {
        return rankedData.map((item, index) => {
            const agentData = activeUsersData.find(user => user.lagnname === item.MGA) || {};
            const mga = agentData.lagnname ? agentData.lagnname.split(' ')[0].toUpperCase() : '';

            // Determine the medal emoji based on rank
            let rankDisplay;
            if (index === 0) {
                rankDisplay = '🥇'; // Gold medal for 1st place
            } else if (index === 1) {
                rankDisplay = '🥈'; // Silver medal for 2nd place
            } else if (index === 2) {
                rankDisplay = '🥉'; // Bronze medal for 3rd place
            } else {
                rankDisplay = item.rank;
            }

            return (
                <tr key={index}>
                    <td className='rank-column-header'>{rankDisplay}</td>
                    <td className='rank-agent-name'>{reformatMGANameWithMGA(item.MGA, mga)}</td>
                    <td>{formatCurrency(item.alp)}</td>
                </tr>
            );
        });
    };

    return (
        <div className="leaderboard-card">
            <table className="ranking-table">
                <thead>
                    <tr>
                        <th className='rank-column-header'></th>
                        <th>MGA</th>
                        <th>ALP</th>
                    </tr>
                </thead>
                <tbody>
                    {renderDailyActivityTableRows(rankedDailyActivityData)}
                </tbody>
            </table>
        </div>
    );
};

export default MGAReportedRank;
