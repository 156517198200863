import React, { useState, useEffect, useRef } from 'react';
import '../../Leaderboards.css';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import TrophyCaseModal from '../Trophy/TrophyCaseModal';

const formatWeeklyDateRange = (dateString) => {
    const date = new Date(dateString);
    const priorSunday = new Date(date);
    priorSunday.setDate(date.getDate() - date.getDay());
    const priorMonday = new Date(priorSunday);
    priorMonday.setDate(priorSunday.getDate() - 6);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedMonday = priorMonday.toLocaleDateString('en-US', options);
    const formattedSunday = priorSunday.toLocaleDateString('en-US', options);

    return `Week of ${formattedMonday} - ${formattedSunday}`;
};

const formatMTDDate = (dateString) => {
    const date = new Date(dateString);
    const priorSunday = new Date(date);

    priorSunday.setDate(date.getDate() - date.getDay());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedSunday = priorSunday.toLocaleDateString('en-US', options);

    return `MTD as of ${formattedSunday}`;
};

const formatYTDDate = (dateString) => {
    const date = new Date(dateString);
    const priorSunday = new Date(date);

    priorSunday.setDate(date.getDate() - date.getDay());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedSunday = priorSunday.toLocaleDateString('en-US', options);

    return `YTD as of ${formattedSunday}`;
};

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const reformatAgtNameWithMGA = (name, mga, clname) => {
    if (!name) return '';

    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];
    const parts = name.split(' ');
    if (parts.length < 2) return name;

    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    let [last, first, middle, ...rest] = parts.length === 3 && parts[2].length === 1 ? parts : [parts[0], parts[1], ...parts.slice(2)];

    if (suffixes.includes(rest[rest.length - 1])) {
        rest.pop();
    }

    const abbreviatedLast = `${capitalize(last.charAt(0))}.`;
    const formattedName = rest.length > 0 
        ? `${capitalize(first)} ${rest.map(capitalize).join(' ')} ${abbreviatedLast}` 
        : `${capitalize(first)} ${abbreviatedLast}`;

    let displayMGA = mga ? mga.split(' ')[0].toUpperCase() : '';
    if (!mga && (clname === 'MGA' || clname === 'RGA')) {
        displayMGA = last.toUpperCase();
    }

    return (
        <>
            <div>{formattedName}</div>
            <div className="mga-value">{displayMGA}</div>
        </>
    );
};

const PowerOfficial = ({ selectedPeriodTab, selectedDate }) => {
    const [officialData, setOfficialData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const agnName = localStorage.getItem('agnName'); // Get agent name from localStorage

    useEffect(() => {
        const fetchOfficialData = async () => {
            try {
                const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getOfficialActivity');
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    setOfficialData(data.data);
                } else {
                    setError('Failed to load official data');
                }
                setLoading(false); // Ensure loading is set to false after data fetch
            } catch (err) {
                setError('Failed to load official data');
            }
        };

        fetchOfficialData();
    }, []);

    useEffect(() => {
        if (officialData.length > 0 && selectedDate) {
            const processed = filterAndProcessData(officialData, selectedPeriodTab, selectedDate);
            setFilteredData(processed);
        }
    }, [officialData, selectedPeriodTab, selectedDate]);

    const filterAndProcessData = (officialData, reportType, date) => {
        const filteredByReport = officialData.filter(item => item.REPORT === reportType);
        const filteredByDate = filteredByReport.filter(item => {
            if (reportType === 'Weekly Recap') {
                const week = formatWeeklyDateRange(item.reportdate);
                return week === formatWeeklyDateRange(date);
            } else if (reportType === 'MTD Recap') {
                return formatMTDDate(item.reportdate) === formatMTDDate(date);
            } else if (reportType === 'YTD Recap') {
                return formatYTDDate(item.reportdate) === formatYTDDate(date);
            }
            return false;
        });

        const data = {};
        filteredByDate.forEach(agtItem => {
            const { LagnName, LVL_1_NET, MGA, clname, MGA_NAME } = agtItem;
            data[LagnName] = { LVL_1_NET: parseFloat(LVL_1_NET || 0), MGA, clname, MGA_NAME };
        });

        return data;
    };

    const getRankedData = (data) => {
        const rankedData = Object.keys(data).map(agtName => {
            const { LVL_1_NET, MGA, clname, MGA_NAME } = data[agtName];
            return { AGT: agtName, LVL_1_NET, MGA, clname, MGA_NAME };
        }).sort((a, b) => b.LVL_1_NET - a.LVL_1_NET)
            .map((item, index) => ({ ...item, rank: index + 1 }));

        return rankedData; // Return all ranked data
    };

    if (loading) {
        return (
            <div className="leaderboard-card table-overlay">
                <div className="loading-overlay">
                    <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={40}
                        width={40}
                    />
                </div>
                <table className="leaderboard-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>AGT</th>
                            <th>LVL_1_NET</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Empty rows to maintain table structure during loading */}
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const rankedOfficialData = getRankedData(filteredData);

    const renderOfficialTableRows = (rankedData) => {
        return rankedData.map((item, index) => {
            // Determine the medal emoji based on rank
            let rankDisplay;
            if (index === 0) {
                rankDisplay = '🥇'; // Gold medal for 1st place
            } else if (index === 1) {
                rankDisplay = '🥈'; // Silver medal for 2nd place
            } else if (index === 2) {
                rankDisplay = '🥉'; // Bronze medal for 3rd place
            } else {
                rankDisplay = item.rank;
            }

            // Extract MGA name
            const mgaName = item.MGA_NAME ? item.MGA_NAME.split(' ')[0].toUpperCase() : item.AGT.split(' ')[0].toUpperCase();

            return (
                <tr key={index}>
                    <td className='rank-column-header'>{rankDisplay}</td>
                    <td className='rank-agent-name'>
                        {reformatAgtNameWithMGA(item.AGT, item.MGA, item.clname)}
                        <div className="mga-value">{mgaName}</div>
                    </td>
                    <td>{formatCurrency(item.LVL_1_NET)}</td>
                </tr>
            );
        });
    };

    const renderStickyRow = (rankedData) => {
        const stickyItem = rankedData.find(item => item.AGT === agnName);

        if (!stickyItem) return null;

        let rankDisplay = `${stickyItem.rank}/${rankedData.length}`;

        const mgaName = stickyItem.MGA_NAME ? stickyItem.MGA_NAME.split(' ')[0].toUpperCase() : stickyItem.AGT.split(' ')[0].toUpperCase();

        return (
            <table className="sticky-row-table">
                <tbody>
                    <tr>
                        <td className='rank-column-header'>{rankDisplay}</td>
                        <td className='rank-agent-name'>
                            {reformatAgtNameWithMGA(stickyItem.AGT, stickyItem.MGA, stickyItem.clname)}
                            <div className="mga-value">{mgaName}</div>
                        </td>
                        <td>{formatCurrency(stickyItem.LVL_1_NET)}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    return (
        <div className="ranking-leaderboard-card">
            <table className="ranking-table">
                <thead>
                    <tr>
                        <th className='rank-column-header'></th>
                        <th className='rank-agent-name'>AGT</th>
                        <th>NET ALP</th>
                    </tr>
                </thead>
                <tbody>
                    {renderOfficialTableRows(rankedOfficialData)}
                </tbody>
            </table>
            {renderStickyRow(rankedOfficialData)}
        </div>
    );
};

export default PowerOfficial;
