import React, { useState, useEffect, useCallback, useMemo } from 'react';
import '../../Leaderboards.css';
import { TailSpin } from 'react-loader-spinner';

const stripTimeFromDate = (dateString) => new Date(dateString.split('T')[0]);

const getMondayOfWeek = (dateString) => {
    const date = stripTimeFromDate(dateString);
    const day = date.getDay();
    const diff = day === 0 ? -6 : 1 - day;
    const monday = new Date(date.setDate(date.getDate() + diff));
    return monday.toISOString().split('T')[0];
};

const formatReportedMTDDate = (dateString) => {
    const date = stripTimeFromDate(dateString);
    return date.toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' }).replace('/', '-');
};

const formatReportedYTDDate = (dateString) => new Date(dateString).getFullYear().toString();

const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
}).format(value);

const reformatAgtNameWithMGA = (name, mga, clname) => {
    if (!name) return '';
    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];
    const parts = name.split(' ');
    if (parts.length < 2) return name;
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    let [last, first, middle, ...rest] = parts.length === 3 && parts[2].length === 1 ? parts : [parts[0], parts[1], ...parts.slice(2)];
    if (suffixes.includes(rest[rest.length - 1])) rest.pop();
    const abbreviatedLast = `${capitalize(last.charAt(0))}.`;
    const formattedName = rest.length > 0 ? `${capitalize(first)} ${rest.map(capitalize).join(' ')} ${abbreviatedLast}` : `${capitalize(first)} ${abbreviatedLast}`;
    let displayMGA = mga ? mga.split(' ')[0].toUpperCase() : '';
    if (!mga && (clname === 'MGA' || clname === 'RGA')) displayMGA = last.toUpperCase();
    return (
        <>
            <div>{formattedName}</div>
            <div className="mga-value">{displayMGA}</div>
        </>
    );
};

const AllReportedRank = ({ selectedPeriodTab, selectedDate, activeUsersData, dailyActivityData }) => {
    const [processedData, setProcessedData] = useState({ weekly: {}, monthly: {}, yearly: {} });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const processReportedData = useCallback((dailyActivityData, activeUsersData) => {
        const data = {
            weekly: {},
            monthly: {},
            yearly: {}
        };
    
        const weekKeys = new Map();
        const monthKeys = new Map();
        const yearKeys = new Map();
    
        // Precompute all date keys
        dailyActivityData.forEach(item => {
            const week = getMondayOfWeek(item.reportDate.split('T')[0]);
            const month = formatReportedMTDDate(item.reportDate.split('T')[0]);
            const year = formatReportedYTDDate(item.reportDate.split('T')[0]);
    
            if (!weekKeys.has(week)) weekKeys.set(week, week);
            if (!monthKeys.has(month)) monthKeys.set(month, month);
            if (!yearKeys.has(year)) yearKeys.set(year, year);
        });
    
        // Initialize all agents in the data structure
        activeUsersData.forEach(user => {
            const agent = user.lagnname;
    
            weekKeys.forEach(week => {
                if (!data.weekly[week]) data.weekly[week] = {};
                if (!data.weekly[week][agent]) data.weekly[week][agent] = { alp: 0, refALP: 0 };
            });
    
            monthKeys.forEach(month => {
                if (!data.monthly[month]) data.monthly[month] = {};
                if (!data.monthly[month][agent]) data.monthly[month][agent] = { alp: 0, refALP: 0 };
            });
    
            yearKeys.forEach(year => {
                if (!data.yearly[year]) data.yearly[year] = {};
                if (!data.yearly[year][agent]) data.yearly[year][agent] = { alp: 0, refALP: 0 };
            });
        });
    
        // Populate data based on daily activity
        dailyActivityData.forEach(item => {
            const agent = item.agent;
            const week = weekKeys.get(getMondayOfWeek(item.reportDate.split('T')[0]));
            const month = monthKeys.get(formatReportedMTDDate(item.reportDate.split('T')[0]));
            const year = yearKeys.get(formatReportedYTDDate(item.reportDate.split('T')[0]));
    
            if (agent && data.weekly[week] && data.weekly[week][agent]) {
                data.weekly[week][agent].alp += parseFloat(item.alp || 0);
                data.weekly[week][agent].refALP += parseFloat(item.refAlp || 0);
                data.monthly[month][agent].alp += parseFloat(item.alp || 0);
                data.monthly[month][agent].refALP += parseFloat(item.refAlp || 0);
                data.yearly[year][agent].alp += parseFloat(item.alp || 0);
                data.yearly[year][agent].refALP += parseFloat(item.refAlp || 0);
            } else {
                console.warn(`Skipping agent: ${agent}, data might not be initialized correctly for week: ${week}, month: ${month}, year: ${year}`);
            }
        });
    
        return data;
    }, []);
    
    const filteredData = useMemo(() => {
        if (selectedPeriodTab === 'Weekly Recap') {
            const [start, end] = selectedDate.split(' - ');
            const weeklyData = dailyActivityData
                .filter(item => {
                    const date = item.reportDate.split('T')[0];
                    return date >= start && date <= end;
                })
                .reduce((acc, item) => {
                    const agent = item.agent;
                    if (!acc[agent]) acc[agent] = { alp: 0, refALP: 0 };
                    acc[agent].alp += parseFloat(item.alp || 0);
                    acc[agent].refALP += parseFloat(item.refAlp || 0);
                    return acc;
                }, {});
    
            activeUsersData.forEach(user => {
                if (!weeklyData[user.lagnname]) {
                    weeklyData[user.lagnname] = { alp: 0, refALP: 0 };
                }
            });
    
            return weeklyData;
        } else if (selectedPeriodTab === 'MTD Recap') {
            const [month, year] = selectedDate.split('-');
            const startOfMonth = `${year}-${month.padStart(2, '0')}-01`;
            const endOfMonth = new Date(year, month, 0).toISOString().split('T')[0];
            
            
            const monthlyData = dailyActivityData
                .filter(item => {
                    const date = item.reportDate.split('T')[0];
                    return date >= startOfMonth && date <= endOfMonth;
                })
                .reduce((acc, item) => {
                    const agent = item.agent;
                    if (!acc[agent]) acc[agent] = { alp: 0, refALP: 0 };
                    acc[agent].alp += parseFloat(item.alp || 0);
                    acc[agent].refALP += parseFloat(item.refAlp || 0);
                    return acc;
                }, {});
            
            activeUsersData.forEach(user => {
                if (!monthlyData[user.lagnname]) {
                    monthlyData[user.lagnname] = { alp: 0, refALP: 0 };
                }
            });
    
            return monthlyData;
        } else if (selectedPeriodTab === 'YTD Recap') {
            const yearlyData = processedData.yearly[selectedDate] || {};
            activeUsersData.forEach(user => {
                if (!yearlyData[user.lagnname]) {
                    yearlyData[user.lagnname] = { alp: 0, refALP: 0 };
                }
            });
    
            return yearlyData;
        }
    }, [selectedPeriodTab, selectedDate, dailyActivityData, processedData, activeUsersData]);
    

    useEffect(() => {
        if (dailyActivityData.length > 0 && activeUsersData.length > 0) {
            const processed = processReportedData(dailyActivityData, activeUsersData);
            setProcessedData(processed);
            setLoading(false);
        }
    }, [dailyActivityData, activeUsersData, processReportedData]);
    

    const getRankedData = useCallback((data) => {
        const activeUsersSet = new Set(activeUsersData.map(user => user.lagnname));
    
        const rankedData = Object.keys(data)
            .filter(agent => activeUsersSet.has(agent))
            .map(agent => {
                const alp = data[agent]?.alp || 0;
                return { AGT: agent, alp };
            }).sort((a, b) => b.alp - a.alp)
            .map((item, index) => ({ ...item, rank: index + 1 }));
    
        return rankedData.slice(0, 3); // Limit to top 3 entries
    }, [activeUsersData]);
    


    const rankedDailyActivityData = useMemo(() => getRankedData(filteredData), [filteredData, getRankedData]);

    const renderDailyActivityTableRows = (rankedData) => {
        return rankedData.map((item, index) => {
            const agentData = activeUsersData.find(user => user.lagnname === item.AGT) || {};
            const mga = agentData.mga || '';
            const clname = agentData.clname || '';
    
            let rankDisplay;
            if (index === 0) {
                rankDisplay = '🥇';
            } else if (index === 1) {
                rankDisplay = '🥈';
            } else if (index === 2) {
                rankDisplay = '🥉';
            } else {
                rankDisplay = item.rank;
            }
    
            return (
                <tr key={index}>
                    <td className='rank-column-header'>{rankDisplay}</td>
                    <td className='rank-agent-name'>{reformatAgtNameWithMGA(item.AGT, mga, clname)}</td>
                    <td>{formatCurrency(item.alp)}</td>
                </tr>
            );
        });
    };
    

    if (loading) {
        return (
            <div className="leaderboard-card table-overlay">
                <div className="loading-overlay">
                    <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={40}
                        width={40}
                    />
                </div>
                <table className="leaderboard-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th className='rank-agent-name'>AGT</th>
                            <th>Score</th>
                            <th>ALP</th>
                            <th>RefALP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan="5">&nbsp;</td></tr>
                        <tr><td colSpan="5">&nbsp;</td></tr>
                        <tr><td colSpan="5">&nbsp;</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="leaderboard-card">
            <table className="reactive-ranking-table">
                <thead>
                    <tr>
                        <th className='rank-column-header'></th>
                        <th className='rank-agent-name'>AGT</th>
                        <th>ALP</th>
                    </tr>
                </thead>
                <tbody>
                    {renderDailyActivityTableRows(rankedDailyActivityData)}
                </tbody>
            </table>
        </div>
    );
    
    
};

export default AllReportedRank;
