import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './HierarchyTable.css'; // Import the CSS for styling

const HierarchyTable = () => {
    const [hierarchyData, setHierarchyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHierarchyData = async () => {
            try {
                // Retrieve userId and agnName from local storage
                const userId = localStorage.getItem('userId');
                const agnName = localStorage.getItem('agnName');

                // Check if userId and agnName are available
                if (!userId || !agnName) {
                    setError('User ID or Agent Name not found in local storage');
                    setLoading(false);
                    return;
                }

                let response;
                if (agnName === 'ROMEROGONZALEZ ARTURO') {
                    // Fetch the hierarchy data using GET request
                    response = await axios.get('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getActiveUsers', {
                        params: { userId }
                    });
                } else {
                    // Fetch the hierarchy data using POST request
                    response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getHierarchyInfo', { userId });
                }

                if (response.data.success) {
                    console.log("Fetched Data:", response.data.data); // Log fetched data
                    setHierarchyData(response.data.data);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                setError('Error fetching hierarchy data');
            } finally {
                setLoading(false);
            }
        };

        fetchHierarchyData();
    }, []);

    const handleActiveChange = async (id, newActiveStatus) => {
        try {
            const response = await axios.post('https://morning-sands-14889-102dd928c446.herokuapp.com/api/updateActiveStatus', {
                id,
                managerActive: newActiveStatus,
            });

            if (response.data.success) {
                setHierarchyData((prevData) =>
                    prevData.map((item) => (item.id === id ? { ...item, managerActive: newActiveStatus } : item))
                );
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error('Error updating active status:', err);
            alert('Error updating active status');
        }
    };

    if (loading) return <div>Loading...</div>; // Display "Loading..." while loading
    if (error) return <div>{error}</div>;

    const buildHierarchy = (data) => {
        const hierarchy = [];
        const map = {};
        const inactiveNodes = [];

        // Initialize map with each item
        data.forEach(item => {
            map[item.lagnname] = { ...item, children: [] };
        });

        // Group children under their respective parents based on the hierarchy rules
        data.forEach(item => {
            const parentKey = item.sa || item.ga || item.mga || item.rga;
            if (item.managerActive === 'n') {
                inactiveNodes.push(map[item.lagnname]);
                if (parentKey && map[parentKey]) {
                    map[parentKey].children = [
                        ...map[parentKey].children,
                        ...map[item.lagnname].children
                    ];
                }
                return;
            }
            if (parentKey && map[parentKey]) {
                map[parentKey].children.push(map[item.lagnname]);
            } else {
                hierarchy.push(map[item.lagnname]);
            }
        });

        // Ensure the hierarchy follows the specified order
        const sortHierarchy = (nodes) => {
            return nodes.sort((a, b) => {
                const order = ['RGA', 'MGA', 'GA', 'SA', 'AGT'];
                const orderA = order.indexOf(a.clname);
                const orderB = order.indexOf(b.clname);

                // Custom order for AGT with null sa and non-null ga before SA with null sa and non-null ga
                if (orderA === orderB) {
                    if (a.clname === 'AGT' && b.clname === 'SA') {
                        if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                            return -1;
                        }
                    } else if (a.clname === 'SA' && b.clname === 'AGT') {
                        if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                            return 1;
                        }
                    }
                    return a.managerActive === 'n' ? 1 : -1;
                }

                return orderA - orderB;
            }).map(node => {
                node.children = sortHierarchy(node.children);
                return node;
            });
        };

        const sortedHierarchy = sortHierarchy(hierarchy);

        console.log("Built Hierarchy:", sortedHierarchy); // Log built hierarchy
        console.log("Inactive Nodes:", inactiveNodes); // Log inactive nodes
        return { sortedHierarchy, inactiveNodes };
    };

    const renderHierarchy = (nodes, isInactive = false) => {
        return nodes.map((node) => (
            <React.Fragment key={node.id}>
                <tr className={isInactive ? 'inactive-row' : ''}>
                    <td>{node.lagnname}</td>
                    <td>{node.agtnum}</td>
                    <td>{node.clname}</td>
                    <td>
                        <select
                            value={node.managerActive}
                            onChange={(e) => handleActiveChange(node.id, e.target.value)}
                        >
                            <option value="y">Yes</option>
                            <option value="n">No</option>
                        </select>
                    </td>
                </tr>
                {!isInactive && renderHierarchy(node.children)}
            </React.Fragment>
        ));
    };

    const { sortedHierarchy, inactiveNodes } = buildHierarchy(hierarchyData);

    return (
        <div className="scroll-container">
            <div className="scroll-wrapper">
                <table className="">
                    <thead>
                        <tr>
                            <th>Agent</th>
                            <th>Agent #</th>
                            <th>Contract</th>
                            <th>Active?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderHierarchy(sortedHierarchy)}
                        {renderHierarchy(inactiveNodes, true)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HierarchyTable;
