import React, { useState, useEffect } from 'react';
import './ReportActivityForm.css';
import globeImage from '../img/globe1.png';
import { trackEvent } from './utils/analytics';

function ReportActivityForm({ closeForm }) {
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const currentDate = new Date().toLocaleDateString('en-CA'); // Format YYYY-MM-DD
    const [userId, setUserId] = useState(localStorage.getItem('userId') || '');

    const [reportDate, setReportDate] = useState(localStorage.getItem('selectedDate') || currentDate);
    const [mgas, setMgas] = useState([]);
    const [agents, setAgents] = useState([]);
    const [mga, setMga] = useState(localStorage.getItem('selectedMGA') || '');
    const [agent, setAgent] = useState(localStorage.getItem('selectedAgent') || '');
    const [rga, setRga] = useState('');
    const [legacy, setLegacy] = useState('');
    const [tree, setTree] = useState('');
    const [sa, setSa] = useState('');
    const [ga, setGa] = useState('');
    const [agtnum, setAgtnum] = useState('');
    const [clname, setClname] = useState('');
    const [errors, setErrors] = useState({
        appts: '',
        sits: '',
        sales: '',
        alp: '',
        refs: '',
        refAppts: '',
        refSits: '',
        refSales: '',
        refAlp: '',
    });

    const [didWork, setDidWork] = useState('yes');
    const [dataExists, setDataExists] = useState(false); // New state variable

    // State variables for main details
    const [appts, setAppts] = useState(0);
    const [sits, setSits] = useState(0);
    const [sales, setSales] = useState(0);
    const [alp, setAlp] = useState(0);
    const [refs, setRefs] = useState(0);

    // State variables for referral details
    const [refAppts, setRefAppts] = useState(0);
    const [refSits, setRefSits] = useState(0);
    const [refSales, setRefSales] = useState(0);
    const [refAlp, setRefAlp] = useState(0);

    const [detailsByLagnname, setDetailsByLagnname] = useState({});

    // Check if there are any error messages
    const hasErrors = Object.values(errors).some(error => error);

    const updateFormData = (key, value) => {
        let newErrors = { ...errors }; // Copy existing errors

        // Convert string value to number for numerical comparison, if it's a numeric field
        const numericValue = !isNaN(value) ? Number(value) : value;

        switch (key) {
            case 'appts':
                setAppts(numericValue);
                // Validate appts when appts is updated
                newErrors = validateSalesAndAlp('main', sales, sits, numericValue, alp, newErrors);
                break;
            case 'sits':
                setSits(numericValue);
                // Validate sits when sits is updated
                newErrors = validateSalesAndAlp('main', sales, numericValue, appts, alp, newErrors);
                break;
            case 'sales':
                setSales(numericValue);
                // Validate sales when sales is updated
                newErrors = validateSalesAndAlp('main', numericValue, sits, appts, alp, newErrors);
                break;
            case 'alp':
                setAlp(numericValue);
                // Validate ALP when ALP is updated
                newErrors = validateSalesAndAlp('main', sales, sits, appts, numericValue, newErrors);
                break;
            case 'refs':
                setRefs(numericValue);
                break;
            case 'refAppts':
                setRefAppts(numericValue);
                // Validate refAppts when refAppts is updated
                newErrors = validateSalesAndAlp('ref', refSales, refSits, numericValue, refAlp, newErrors);
                break;
            case 'refSits':
                setRefSits(numericValue);
                // Validate refSits when refSits is updated
                newErrors = validateSalesAndAlp('ref', refSales, numericValue, refAppts, refAlp, newErrors);
                break;
            case 'refSales':
                setRefSales(numericValue);
                // Validate refSales when refSales is updated
                newErrors = validateSalesAndAlp('ref', numericValue, refSits, refAppts, refAlp, newErrors);
                break;
            case 'refAlp':
                setRefAlp(numericValue);
                // Validate refAlp when refAlp is updated
                newErrors = validateSalesAndAlp('ref', refSales, refSits, refAppts, numericValue, newErrors);
                break;
            default:
                break;
        }

        setErrors(newErrors); // Update the errors state

        // Persist data to localStorage or handle other updates
        const localStorageKey = `agentData_${agent}_${reportDate}`;
        let formData = JSON.parse(localStorage.getItem(localStorageKey)) || {};

        formData[key] = numericValue; // Update the specific field in the form data
        localStorage.setItem(localStorageKey, JSON.stringify(formData)); // Save the updated form data back to local storage
    };

    const validateSalesAndAlp = (prefix, sales, sits, appts, alp, existingErrors) => {
        let errors = { ...existingErrors };
        const apptsKey = `${prefix}Appts`;
        const sitsKey = `${prefix}Sits`;
        const salesKey = `${prefix}Sales`;
        const alpKey = `${prefix}Alp`;

        // Validate sits: Sits should not be greater than appts but can be less or equal
        errors[sitsKey] = sits > appts ? `Sits cannot be greater than appts.` : '';

        // Validate sales: Sales should not be greater than sits but can be less or equal
        errors[salesKey] = sales > sits ? `Sales cannot be greater than sits.` : '';

        // Validate ALP: ALP can be > 0 if there's at least one sale
        errors[alpKey] = alp > 0 && sales < 1 ? 'Must have at least one sale to have ALP.' : '';

        return errors;
    };

    const loadFormDataFromLocalStorage = () => {
        const localStorageKey = `agentData_${agent}_${reportDate}`;
        const savedData = localStorage.getItem(localStorageKey);
        if (savedData) {
            const formData = JSON.parse(savedData);
            setAppts(formData.appts || 0);
            setSits(formData.sits || 0);
            setSales(formData.sales || 0);
            setAlp(formData.alp || 0);
            setRefs(formData.refs || 0);
            setRefAppts(formData.refAppts || 0);
            setRefSits(formData.refSits || 0);
            setRefSales(formData.refSales || 0);
            setRefAlp(formData.refAlp || 0);
        } else {
            // Set default values if no data is found in local storage
            setAppts(0);
            setSits(0);
            setSales(0);
            setAlp(0);
            setRefs(0);
            setRefAppts(0);
            setRefSits(0);
            setRefSales(0);
            setRefAlp(0);
        }
    };

    useEffect(() => {
        const loadFormData = () => {
            const localStorageKey = `agentData_${agent}_${reportDate}`;
            const savedData = localStorage.getItem(localStorageKey);
            if (savedData) {
                const formData = JSON.parse(savedData);
                setAppts(formData.appts || 0);
                setSits(formData.sits || 0);
                setSales(formData.sales || 0);
                setAlp(formData.alp || 0);
                setRefs(formData.refs || 0);
                setRefAppts(formData.refAppts || 0);
                setRefSits(formData.refSits || 0);
                setRefSales(formData.refSales || 0);
                setRefAlp(formData.refAlp || 0);
                setSa(formData.sa || '');
                setGa(formData.ga || '');
                setMga(formData.mga || '');
                setRga(formData.rga || '');
                setAgtnum(formData.agtnum || '');
                setClname(formData.clname || '');
                if ((formData.clname === 'MGA' || formData.clname === 'RGA') && !formData.mga) {
                    setMga(formData.lagnname);
                }
            } else {
                // Initialize fields to 0 or other default values if no saved data
                setAppts(0);
                setSits(0);
                setSales(0);
                setAlp(0);
                setRefs(0);
                setRefAppts(0);
                setRefSits(0);
                setRefSales(0);
                setRefAlp(0);
            }
        };

        loadFormData();
    }, [agent, reportDate]);

    const loadFormDataFromDatabase = async () => {
        try {
            const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getDailyActivity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ agent, reportDate }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success && data.activity) {
                    const activity = data.activity;
                    setAppts(activity.appts || 0);
                    setSits(activity.sits || 0);
                    setSales(activity.sales || 0);
                    setAlp(activity.alp || 0);
                    setRefs(activity.refs || 0);
                    setRefAppts(activity.refAppts || 0);
                    setRefSits(activity.refSits || 0);
                    setRefSales(activity.refSales || 0);
                    setRefAlp(activity.refAlp || 0);
                    setSa(activity.sa || '');
                    setGa(activity.ga || '');
                    setMga(activity.mga || '');
                    setRga(activity.rga || '');
                    setAgtnum(activity.agtnum || '');
                    setClname(activity.clname || '');
                    if ((activity.clname === 'MGA' || activity.clname === 'RGA') && !activity.mga) {
                        setMga(activity.lagnname);
                    }
                    setDataExists(true); // Set dataExists to true if data is found
                } else {
                    setDataExists(false); // Set dataExists to false if no data is found
                }
            } else {
                loadFormDataFromLocalStorage();
                setDataExists(false);
            }
        } catch (error) {
            console.error('Error fetching daily activity data', error);
            loadFormDataFromLocalStorage();
            setDataExists(false);
        }
    };

    useEffect(() => {
        if (agent && reportDate) {
            loadFormDataFromDatabase();
        }
    }, [agent, reportDate]);

    useEffect(() => {
        localStorage.setItem('selectedMGA', mga);
        localStorage.setItem('selectedAgent', agent);
    }, [mga, agent]);

    useEffect(() => {
        const fetchAgentDetails = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/getHierarchyInfo', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId }),
                });
                const data = await response.json();
                if (data.success) {
                    populateLagnnameDropdown(data.data, data.agnName);
                } else {
                    console.error('Failed to fetch details for userId');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
    
        fetchAgentDetails();
    }, []);

    const populateLagnnameDropdown = (data, agnName) => {
        const newDetails = {};

        const agentOptions = data.map(item => {
            // Log each item's details to check the values being processed
            console.log(`Processing agent: ${item.lagnname}, MGA: ${item.mga}, RGA: ${item.rga}, CLName: ${item.clname}`);
            newDetails[item.lagnname] = {
                rept_name: item.rept_name,
                email: item.email,
                mga: item.mga,
                rga: item.rga,
                sa: item.sa,
                ga: item.ga,
                agtnum: item.agtnum,
                clname: item.clname,
                sa_email: item.sa_email,
                ga_email: item.ga_email,
                mga_email: item.mga_email,
                rga_email: item.rga_email,
            };
            return item.lagnname;
        });

        setDetailsByLagnname(newDetails);
        setAgents(agentOptions);

        // Set the dropdown to select the agnName by default if it exists
        if (agnName && agentOptions.includes(agnName)) {
            setAgent(agnName);
            const details = newDetails[agnName];
            updateAgentFields(details);

            // Check if MGA value is present, if not use the agent name as MGA
            if (!details.mga) {
                setMga(agnName);
            } else {
                console.log(`Set MGA for ${agnName}: ${details.mga}`);
            }
        }
    };

    const updateAgentFields = (details) => {
        if (!details) {
            console.error("No details provided for the selected agent.");
            return;
        }

        console.log(`Processing agent: ${details.lagnname}, MGA: ${details.mga}, RGA: ${details.rga}, CLName: ${details.clname}`);

        setSa(details.sa || '');
        setGa(details.ga || '');
        setRga(details.rga || '');
        setAgtnum(details.agtnum || '');
        setClname(details.clname || '');

        if ((details.clname === 'RGA' || details.clname === 'MGA') && !details.mga) {
            if (details.lagnname) {
                setMga(details.lagnname);
                console.log(`MGA was null. Setting MGA to agent's name: ${details.lagnname}`);
            } else {
                console.error("Agent name (lagnname) is undefined; unable to set MGA.");
                setMga(''); // Set to empty string or handle as appropriate
            }
        } else {
            setMga(details.mga || details.lagnname); // Use MGA if available, otherwise fallback to agent's name
            console.log(`MGA set for ${details.lagnname}: ${details.mga || details.lagnname}`);
        }
    };

    useEffect(() => {
        // This useEffect will log the current state of MGA after it has been set
        console.log(`MGA input is being set to: ${mga}`);
    }, [mga]); // This ensures the log reflects the latest value of mga after state updates

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        setShowLoading(true); // Show loading overlay

        // Constructing the payload as JSON
        const payload = {
            userId,
            mga,
            agent,
            reportDate,
            rga,
            legacy,
            tree,
            sa,
            ga,
            agtnum,
            clname,
            appts,
            sits,
            sales,
            alp,
            refs,
            refAppts,
            refSits,
            refSales,
            refAlp
        };

        console.log("Payload:", payload);
        try {
            const response = await fetch('https://morning-sands-14889-102dd928c446.herokuapp.com/api/submitDailyActivity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setShowLoading(false); // Hide loading overlay
                setShowSuccess(true); // Show success overlay

                setTimeout(() => {
                    setShowSuccess(false); // Hide success overlay after 3 seconds
                }, 3000);

            } else {
                // Handle errors
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error state
            setShowLoading(false); // Ensure loading overlay is hidden on error
        }
    };

    const handleDidWorkChange = (value) => {
        setDidWork(value);
        if (value === 'yes') {
            // Focus on the first checkbox
        }
    };

    const handleReportActivityFormSubmit = () => {
        trackEvent("report_activity_submit", {
            category: "Engagement",
            action: "Submit",
            label: "Report Activity Form"
        });
    };

    const showTotalCallsWarning = refs > 100;
    const showTotalApptsWarning = appts > 40;
    const showTotalSitsWarning = sits > 30;
    const showTotalSalesWarning = sales > 20;
    const showTotalAlpWarning = alp > 20000;
    const showRefApptsWarning = refAppts > 20;
    const showRefSitsWarning = refSits > 15;
    const showRefSalesWarning = refSales > 10;
    const showRefAlpWarning = refAlp > 10000;

    return (
        <div className="report-form-container"> 
            <button onClick={closeForm} className="report-close-button">X</button>

            {showLoading && (
                <div className="report-overlay">
                    <img src={globeImage} alt="Globe" className="report-globe-animation" />
                </div>
            )}

            {showSuccess && (
                <div className="report-overlay">
                    <img src={globeImage} alt="Loading..." className="report-globe-animation" />
                    <div className="report-success-overlay">
                        <svg className="report-checkmark report-success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="report-checkmark_circle_success" cx="26" cy="26" r="25" fill="none" />
                            <path className="report-checkmark_check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
            )}

            {submissionStatus === 'error' && (
                <div className="report-overlay">
                    <svg className="report-checkmark report-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="report-checkmark_circle_error" cx="26" cy="26" r="25" fill="none" />
                        <path className="report-checkmark_check" strokeLinecap="round" fill="none" d="M16 16 36 36 M36 16 16 36" />
                    </svg>
                </div>
            )}

            <form onSubmit={handleSubmit}>
                {/* Agent and MGA Dropdowns */}
                <div className="report-dropdown-group">
                    {/* Agent Dropdown */}
                    <div className="report-dropdown-field-group">
                        <label htmlFor="agent">Agent:</label>
                        <select
                            id="agent"
                            value={agent}
                            name='agent'
                            onChange={(e) => {
                                setAgent(e.target.value);
                                updateAgentFields(detailsByLagnname[e.target.value]);
                            }}
                            className="report-dropdown-input-field"
                        >
                            {agents.map(a => <option key={a} value={a}>{a}</option>)}
                        </select>
                    </div>

                    <div className="report-dropdown-field-group">
                        <label htmlFor="reportDate">Date:</label>
                        <input
                            type="date"
                            id="reportDate"
                            value={reportDate}
                            name='reportDate'
                            onChange={(e) => setReportDate(e.target.value)}
                            max={currentDate} // Set the max attribute to the current date
                            className="report-dropdown-input-field"
                        />
                    </div>
                </div>

                {/* Did You Work Today? Radio Buttons */}
                <div className="report-radio-input-field-group">
                    <label>
                        Did you have activity on {new Date(reportDate + 'T00:00').toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric'
                        })}?
                    </label>
                    <div className="report-radio-buttons">
                        <input
                            type="radio"
                            id="workYes"
                            name="didWork"
                            value="yes"
                            checked={didWork === 'yes'}
                            onChange={() => handleDidWorkChange('yes')}
                        />
                        <label htmlFor="workYes">Yes</label>
                        <input
                            type="radio"
                            id="workNo"
                            name="didWork"
                            value="no"
                            checked={(didWork === 'no')}
                            onChange={() => setDidWork('no')}
                        />
                        <label htmlFor="workNo">No</label>
                    </div>
                </div>

                <div>
                    <div className="report-input-group">
                        <input
                            hidden
                            type="text"
                            id="sa"
                            name="sa"
                            value={sa}
                            readOnly
                            className="report-input-field"
                        />
                    </div>
                    <div className="report-input-group">
                        <input 
                            hidden
                            type="text"
                            id="ga"
                            name="ga"
                            value={ga}
                            readOnly
                            className="report-input-field"
                        />
                    </div>
                    <div className="report-input-group">
                        <input 
                            hidden
                            type="text"
                            id="mga"
                            name="mga"
                            value={mga}
                            readOnly
                            className="report-input-field"
                        />
                    </div>
                    <div className="report-input-group">
                        <input 
                            hidden
                            type="text"
                            id="rga"
                            name="rga"
                            value={rga}
                            readOnly
                            className="report-input-field"
                        />
                    </div>
                    <div className="report-input-group">
                        <input 
                            hidden
                            type="text"
                            id="agtnum"
                            name="agtnum"
                            value={agtnum}
                            readOnly
                            className="report-input-field"
                        />
                    </div>
                    <div className="report-input-group">
                        <input 
                            hidden
                            type="text"
                            id="clname"
                            name="clname"
                            value={clname}
                            readOnly
                            className="report-input-field"
                        />
                    </div>
                </div>

                {didWork === 'yes' && (
                    <div className="report-section">
                        {/* Error Message Container */}
                        <div className="report-error-message-container">
                            {Object.values(errors).some(error => error) && (
                                <div className="report-error-message">
                                    {Object.entries(errors).map(([key, message]) => message && <div key={key}>{message}</div>)}
                                </div>
                            )}
                        </div>

                        {/* Main Section */}
                        <div className="report-input-row">
                            <div className="report-input-group">
                                <label htmlFor="appts">Appts</label>
                                <input
                                    type="number"
                                    id="appts"
                                    name="appts"
                                    value={appts}
                                    onChange={(e) => updateFormData('appts', e.target.value)}
                                    className="report-input-field"
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <div className="report-input-group">
                                <label htmlFor="sits">Sits</label>
                                <input
                                    type="number"
                                    id="sits"
                                    name='sits'
                                    value={sits}
                                    onChange={(e) => updateFormData('sits', e.target.value)}
                                    className={`report-input-field ${errors.sits ? 'error' : ''}`}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <div className="report-input-group">
                                <label htmlFor="sales">Sales</label>
                                <input
                                    type="number"
                                    id="sales"
                                    name="sales"
                                    value={sales}
                                    onChange={(e) => updateFormData('sales', e.target.value)}
                                    className={`report-input-field ${errors.sales ? 'error' : ''}`}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <div className="report-input-group">
                                <label htmlFor="alp">Total ALP</label>
                                <input
                                    type="number"
                                    id="alp"
                                    name="alp"
                                    value={alp}
                                    onChange={(e) => updateFormData('alp', e.target.value)}
                                    className={`report-input-field ${errors.alp ? 'error' : ''}`}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <div className="report-input-group">
                                <label htmlFor="refs">Refs Collected</label>
                                <input
                                    type="number"
                                    id="refs"
                                    name='refs'
                                    value={refs}
                                    onChange={(e) => updateFormData('refs', e.target.value)}
                                    className="report-input-field"
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                        </div>

                        {/* Referral Section */}
                        <div className="report-input-row">
                            <div className="report-input-group">
                                <label htmlFor="refAppts">Ref Appts</label>
                                <input
                                    type="number"
                                    id="refAppts"
                                    name="refAppts"
                                    value={refAppts}
                                    onChange={(e) => updateFormData('refAppts', e.target.value)}
                                    className="report-input-field"
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <div className="report-input-group">
                                <label htmlFor="refSits">Ref Sits</label>
                                <input
                                    type="number"
                                    id="refSits"
                                    name='refSits'
                                    value={refSits}
                                    onChange={(e) => updateFormData('refSits', e.target.value)}
                                    className={`report-input-field ${errors.refSits ? 'error' : ''}`}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <div className="report-input-group">
                                <label htmlFor="refSales">Ref Sales</label>
                                <input
                                    type="number"
                                    id="refSales"
                                    name="refSales"
                                    value={refSales}
                                    onChange={(e) => updateFormData('refSales', e.target.value)}
                                    className={`report-input-field ${errors.refSales ? 'error' : ''}`}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <div className="report-input-group">
                                <label htmlFor="refAlp">Ref ALP</label>
                                <input
                                    type="number"
                                    id="refAlp"
                                    name="refAlp"
                                    value={refAlp}
                                    onChange={(e) => updateFormData('refAlp', e.target.value)}
                                    className={`report-input-field ${errors.refAlp ? 'error' : ''}`}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {showTotalCallsWarning && <div className="report-warning-message">Warning: Refs seem high for daily activity. Check for accuracy.</div>}
                {showTotalApptsWarning && <div className="report-warning-message">Warning: Appts seem high for daily activity. Check for accuracy.</div>}
                {showTotalSitsWarning && <div className="report-warning-message">Warning: Sits seem high for daily activity. Check for accuracy.</div>}
                {showTotalSalesWarning && <div className="report-warning-message">Warning: Sales seem high for daily activity. Check for accuracy.</div>}
                {showTotalAlpWarning && <div className="report-warning-message">Warning: Total ALP seems high for daily activity. Check for accuracy.</div>}
                {showRefApptsWarning && <div className="report-warning-message">Warning: Referral appts seem high for daily activity. Check for accuracy.</div>}
                {showRefSitsWarning && <div className="report-warning-message">Warning: Referral sits seem high for daily activity. Check for accuracy.</div>}
                {showRefSalesWarning && <div className="report-warning-message">Warning: Referral sales seem high for daily activity. Check for accuracy.</div>}
                {showRefAlpWarning && <div className="report-warning-message">Warning: Referral ALP seems high for daily activity. Check for accuracy.</div>}

                <input type="submit" value={dataExists ? 'Submit Changes' : 'Submit'} className="report-submit-button" disabled={hasErrors} onClick={handleReportActivityFormSubmit} />
            </form>
        </div>
    );
}

export default ReportActivityForm;
