import React from 'react';
import './GridContent.css';

const ImportantWebsites = () => {
  return (
    <div className="grid-content" id="importantWebsites">
      <div className="grid-cat">
        <div className="category">Important Websites</div>
        <div className="category-link"><a href="https://impact.ailife.com/" target="_blank" rel="noopener noreferrer">Agent Workspace</a></div>
        <div className="category-link"><a href="https://icmail.globelifeinc.com/login.html" target="_blank" rel="noopener noreferrer">ICM Report Portal</a></div>
        <div className="category-link"><a href="https://impactmobile.ailife.com" target="_blank" rel="noopener noreferrer">IMPACT</a></div>
        <div className="category-link"><a href="https://arc.ailife.com/" target="_blank" rel="noopener noreferrer">Agency Resource Center</a></div>
        <br />
        <div className="category-link"><a href="https://prodwebapps.ailife.com/APSWeb/login.aspx" target="_blank" rel="noopener noreferrer">AI Records</a></div>
        <ul>
          <li>un: Airecords</li>
          <li>pw: Agents1</li>
        </ul>
      </div>
    </div>
  );
};

export default ImportantWebsites;
